import React from "react";
import { useStateValue} from '../store/StateProvider'
import { Route, Redirect } from "react-router-dom";

function PrivateRoute({ children, ...rest }) {
    const [state] = useStateValue();

    if (!state.auth.isAuthenticated){
        return <Redirect  to="/login"/>
    }

    return <Route {...rest}/>
    ;
}
export default PrivateRoute