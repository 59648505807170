import React, { useEffect } from "react";
import { useStateValue } from "../../store/StateProvider";
import { createUseStyles } from "react-jss";
import { logout } from "../../store/actions/authActions";
import { getUserNotes } from "../../store/actions/noteActions";
import NoteListItemPrevious from "../noteListItemPrevious";
const useStyles = createUseStyles({
  title: {
    alignSelf: "center",
    width: "100px",
  },
  backLink: {
    alignSelf: "center",
    width: "100%",
    color: "black",
    textAlign: "center",
    height: "40px",
    paddingTop: "5px",
  },
  noteList: {
    width: "350px" /* "25%" */,
    borderRight: "1px solid silver",
    // display: "flex",
    // flexDirection: "column",
    height: "90%",
    overflow: "auto",
  },
  noNotes: {
    alignSelf: "center",
    padding: "20px",
    fontColor: "grey",
  },
  noteMenu: {
    borderBottom: "1px solid silver",
    "& div:not(:first-child)": {
      borderLeft: "1px solid silver",
    },
    "& div:hover": {
      backgroundColor: "rgb(211, 217, 223)",
      padding: "10px 0px",
    },
    "& div": {
      padding: "10px 0px",
      cursor: "pointer",
    },
    "& div.selected": {
      backgroundColor: "rgb(211, 217, 223)",
    },
  },
});

function PreviousUserNotesPending() {
  const classes = useStyles();
  const [state, dispatch] = useStateValue();

  const fetchData = async () => {
    dispatch(
      await getUserNotes(1, sessionStorage.getItem("selectedNoteUserId"))
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let id = setInterval(fetchData, 5000);
    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    if (state.notes.error.errorCode === 401) {
      dispatch(logout());
    }
  }, [state.notes.error.errorCode]);

  return (() => {
    let list;
    list = (
      <div style={{ overflow: "auto" }}>
        {state.notes.userNotes.map((item, index) => (
          <NoteListItemPrevious index={index + 1} key={item.id} note={item} />
        ))}
        {!state.notes.userNotes.length && (
          <div className={classes.noNotes}> No reviewed notes</div>
        )}
      </div>
    );
    return (
      <div>
        <p
          style={{
            paddingTop: "1rem",
            paddingLeft: "1rem",
            paddingBottom: "1rem",
          }}
        >
          Previous Notes ({state?.notes?.userNotes.length})
        </p>
        {state.notes.selectedNote.id || state.notes.selectedCheckedNote.id ? (
          <div className={classes.noteList}>{list}</div>
        ) : null}
      </div>
    );
  })();
}

export default PreviousUserNotesPending;
