import React from 'react';
import Login from '../login/index'
import PrivateRoute from '../../helpers/privateRoute'
import homePage from "../homePage"

import {Route, Switch} from "react-router-dom";
import GuestRoute from '../../helpers/guestRoute';
import NoteListUser from '../noteListUser'
import NoteInfoUser from "../noteInfoUser";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    mainArea:{
        display:'flex',
        height: '100%',
        overflow: 'hidden',
    },}))


function Main() {
    const classes = useStyles();
  return (
    <div className="App" style={{backgroundColor:"rgb(240, 240, 240)"}}>
      <Switch>

        <Route path="/NoteListUser" render={() =>
            <div className={classes.mainArea}>
                <NoteListUser/>
                <NoteInfoUser/>
            </div>}
        />
        <GuestRoute path="/login" component={Login}/>
        <PrivateRoute path="/" component={homePage}/>

      </Switch>
    </div>
  );
}

export default Main;

