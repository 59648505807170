import React, {useState, useEffect, useRef} from 'react';
import {useStateValue} from '../../store/StateProvider'
import {createUseStyles} from 'react-jss'
import {selectCheckedNote, getCheckedNotes, updateCheckedNote} from "../../store/actions/noteActions"
import Player from '../player'
import {languages} from "../../languagesList"
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import "./noteInfoSubmitted.css";

const useStyles = createUseStyles({
    noteInfoPage: {
        width: "100%",
        height: "100%",
        overflow: "auto",
    },
    noteInfo: {
        width: "800px",
        height: "100%",
        overflow: "auto",
    },
    infoHeader: {
        display: "flex",
        justifyContent: "space-between",
    },
    wrapper: {
        width: "400px",
        alignItems: "center",
        backgroundColor: "rgb(255, 255, 255)",
    },
   
    workArea: {
        textAlign: "center",
    },
    
    updateButton: {
        width: "180px",
        height: "65px",
        backgroundColor: "#57C58E",
        display: "inline-block",
        borderRadius: "1px",
        padding: "10px",
        paddingTop: "20px",
        marginBottom: "10px",
        marginTop: "5px",
    
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "green",
        },
    },
    releaseButton: {
        width: "120px",
        height: "41px",
        backgroundColor: "#F3F995",
        fontSize: "12px",
        display: "inline-block",
        borderRadius: "1px",
        padding: "4px",
        paddingLeft: "12px",
        textAlign: "left",
        marginRight: "85px",
        marginTop: "42px",
    
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#E8FF00",
        },
    },
    
    textArea: {
        resize: "none",
        border: "1px solid black",
        margin: "0px 0px",
    },
    
    statusContainer: {
        minWidth: "200px",
        textAlign: "right",
        height: "60px",
    },

    noteInfo:{
        width:"845px",
        height:"100%",
        padding:"10px",
        overflow: "auto"
    },
    infoHeader:{
        display:"flex",
        justifyContent:"space-between"
    },
    wrapper:{
        display: 'flex',
        justifyContent: "space-around",
        alignItems: "center"
    },
    workArea:{
        minWidth:'300px',
        textAlign:'center'
    },
    updateButton:{
        backgroundColor: '#57C58E',
        display: 'inline-block',
        backgoroundColor: 'white',
        border: '1px solid silver',
        borderRadius: '3px',
        padding:'8px',
        marginTop: '10px',
        '&:hover':{
            cursor: 'pointer',
            backgroundColor: 'green'
        }
    },
    textArea:{
        resize: 'none',
        border: '1px solid silver',
        borderRadius: '4px',
        margin: '0px 5px'
    },
    statusContainer:{
        minWidth: "200px",
        textAlign: "right"
    }
})

function NoteInfoSubmitted() {

    const classes = useStyles()
    const [state, dispatch] = useStateValue();
    const textAreaRef = useRef(null)

    const handleInputChange = event => {
        dispatch(selectCheckedNote({
                ...state.notes.selectedCheckedNote,
                text: event.target.value
            })
        )
    };
    const updateNote = async () => {
        dispatch(await updateCheckedNote(state.notes.selectedCheckedNote))
        dispatch(await getCheckedNotes())
    }

    useEffect(()=> {
        if(textAreaRef.current){
            textAreaRef.current.focus()
        }
    })

    const renderUserInfo = (user) => {
        if (
        localStorage.getItem("userAdminEmail") === "operator-18@senstone.io" ||
        localStorage.getItem("userAdminEmail") === "operator-19@senstone.io" ||
        localStorage.getItem("userAdminEmail") === "operator-20@senstone.io"
        ) {
            return "Access level required";
        } else {
            return user;
        }
    };

    return (
        // <div className={classes.noteInfo}>
        //     {state.notes.selectedCheckedNote.id ? (
        //             <div>
        //                 <div>
        //                     <div>
        //                         Created at: { new Date (state.notes.selectedCheckedNote.created_at).toString() }
        //                     </div>
        //                     <div>
        //                         Submitted at: { new Date (state.notes.selectedCheckedNote.updated_at).toString() }
        //                     </div>
        //                     <div>
        //                         Operator id:  {state.notes.selectedCheckedNote.operator_id}
        //                     </div>
        //                 </div>
        //                 <div className={classes.infoHeader}>
        //                   <div>
        //                       Language: {languages[state.notes.selectedCheckedNote.language]}
        //                   </div>
        //                   <h2>
        //                       <strong>DONE</strong>
        //                   </h2>
        //                 </div>

        //                 <div className={classes.wrapper}>
        //                     <div style={{}}>
        //                         <div className={classes.workArea}>
        //                             <div>Text of the note</div>
        //                             <textarea
        //                                 type="text"
        //                                 value={state.notes.selectedCheckedNote.text}
        //                                 onChange={handleInputChange}
        //                                 name="text"
        //                                 id="text"
        //                                 rows="12"
        //                                 cols="40"
        //                                 className={classes.textArea}
        //                                 ref={textAreaRef}
        //                             />
        //                             { <div>
        //                                 <div style={{display:"inline-block", width:"50%"}}>
        //                                     <label>
        //                                         <span style={{paddingLeft:'3px'}} >
        //                                             It's "todo": {state.notes.selectedCheckedNote.is_todo === false ? 'No' : 'Yes'}
        //                                         </span>
        //                                     </label>
        //                                 </div>
        //                                 <div style={{display:"inline-block", width:"50%"}}>
        //                                     <div>
        //                                         <label>
        //                                             <span style={{paddingLeft:'3px'}}>
        //                                                 It's reminder: {state.notes.selectedCheckedNote.fired_at === null ? 'No' : 'Yes'}
        //                                             </span>
        //                                         </label>
        //                                     </div>

        //                                 </div>
        //                             </div> }
        //                             <div className='pb-1'>
        //                                 <div  className={classes.updateButton} onClick={updateNote}>
        //                                     Save and submit
        //                                 </div>
        //                             </div>
        //                         </div>

        //                     </div>

        //                 </div>
        //                 <Player url={state.notes.selectedCheckedNote.url}/>
        //             </div>
        //         )
        //         : null}
        // </div>

        <div className={classes.noteInfoPage}>
            <div className={classes.noteInfo}>
            {state.notes.selectedCheckedNote.id ? (
                <div style={{ justifyContent: "space-between", display: "flex" }}>
                <div className={classes.wrapper}>
                    <div className={classes.workArea}>
                    <div style={{ height: "45px", marginTop: "15px" }}>
                        {state.notes.selectedCheckedNote.subscription_id === null ? (
                        <h4>Basic</h4>
                        ) : (
                        <h4>Premium</h4>
                        )}
                    </div>
                    <textarea
                        height="300px"
                        type="text"
                        value={state.notes.selectedCheckedNote.text}
                        onChange={handleInputChange}
                        name="text"
                        id="text"
                        rows="15"
                        cols="48"
                        className={classes.textArea}
                        ref={textAreaRef}
                    />
                    <div
                        style={{
                        textAlign: "center",
                        marginLeft: "40px",
                        marginBottom: "10px",
                        }}
                    >
                        <Player url={state.notes.selectedCheckedNote.url} />
                    </div>
                    </div>
                </div>
                <div
                    style={{
                    backgroundColor: "rgb(255, 255, 255)",
                    width: "400px",
                    paddingRight: "15px",
                    alignItems: "center",
                    }}
                >
                    <div className={'done-icon ' + classes.statusContainer}>
                        <CheckCircleOutlineIcon />
                    </div>
                    <div
                    style={{
                        padding: "5px",
                        height: "305px",
                        border: "1px solid black",
                        backgroundColor: "rgb(255, 255, 255)",
                    }}
                    >
                        <div>
                            Language: {languages[state.notes.selectedCheckedNote.language]}
                        </div>

                        <div>
                            Created at:{" "}
                            {new Date(state.notes.selectedCheckedNote.created_at).toString()}
                        </div>
                        <div>
                            {"Local Timezone: "}
                            {state.notes.selectedCheckedNote.record.recorder_at_timezone}
                            <br />
                            {"Submitted At: "}
                            {new Date(state.notes.selectedCheckedNote.updated_at).toString()}
                            <br />
                            {"Operator ID: "}
                            {state.notes.selectedCheckedNote.operator_id}
                            <br />
                            {"User Name: "}
                            {renderUserInfo(
                            state.notes.selectedNoteUserInformation.first_name
                            )}
                            <br />
                            {"User Email: "}
                            {renderUserInfo(
                            state.notes.selectedNoteUserInformation.email
                            )}
                        </div>
                        <p>NB: Convert into local timezone when setting a reminder.</p>
                        <div>
                            {"Time selected by reminder: "}
                            {new Date(state.notes.selectedCheckedNote.fired_at).getTime() === 0
                            ? "none"
                            : new Date(state.notes.selectedCheckedNote.fired_at).toString()}
                        </div>
                        <p></p>

                        { <div>
                            <div style={{display:"inline-block", width:"50%"}}>
                                <label>
                                    <span style={{paddingLeft:'3px'}} >
                                        It's "todo": {state.notes.selectedCheckedNote.is_todo === false ? 'No' : 'Yes'}
                                    </span>
                                </label>
                            </div>
                            <div style={{display:"inline-block", width:"50%"}}>
                                <div>
                                    <label>
                                        <span style={{paddingLeft:'3px'}}>
                                            It's reminder: {state.notes.selectedCheckedNote.fired_at === null ? 'No' : 'Yes'}
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div> }
                    </div>

                    <div style={{ textAlign: "right", marginTop: "45px" }}>
                    
                    <button 
                        type="button" 
                        className="btn btn-success btn-note-save" 
                        onClick={updateNote}>
                        Save and submit
                    </button>

                    </div>
                </div>
                </div>
            ) : null}
            </div>
        </div>
    )
}
export default NoteInfoSubmitted