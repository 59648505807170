import React from 'react';
import config from "../../config.js"
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    textCols: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    paddingTop: "1rem",
    paddingBottom: "1rem"
    },
    textColsItem: {
    paddingLeft: "1rem",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    }
}));

function Dashboard()  {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.textCols}>
                <div className={classes.textColsItem}>
                   Administrator:{" "} {localStorage.getItem('userAdminEmail')}
                   <br/>
                   <a href='https://docs.google.com/document/d/1HJwSGs0DiYoXZkuwh_r70Mkv4-i8PQrp9C7CZv6J9c4/edit?usp=sharing'
                      target="_blank" rel="noopener noreferrer">
                       Editing Guidelines
                   </a>
                </div>
                <div className={classes.textColsItem}>
                     Questions? app@senstone.io
                   <br/>
                   Version: {" "}[{config.appVersion}]
                </div>
            </div>
        </div>
    );
}

export default Dashboard
