const stage = {
    "baseUrl": "https://stage-api.senstone.io",
    "appVersion": ""
}

const prod = {
    "baseUrl": "https://api.senstone.io",
    "appVersion": ""
}

var config = {}
switch (process.env.REACT_APP_STAGE){
    case "stage": config = stage; break;
    case "prod":  config = prod; break;
    default: config = stage;

}

config["appVersion"] = process.env.REACT_APP_VERSION;

export default {
    ...config
}
