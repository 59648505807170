import config from "../config";

export const USER_ENDPOINT = {
  LOGIN: `${config.baseUrl}/v1/admin/login`,
  ADMIN_USERS: `${config.baseUrl}/admin/users`,
  ADMIN_EMAIL: `${config.baseUrl}/admin/email/send`,
  ADMIN_EMAIL_ALL: `${config.baseUrl}/admin/email/send_all`,
  ADMIN_NOTIFICATION_SEND: `${config.baseUrl}/admin/notifications/send`,
  ADMIN_NOTIFICATION_SEND_ALL: `${config.baseUrl}/admin/notifications/send_all`,
  ADMIN_PREMIUM_SUBSCRIPTION: `${config.baseUrl}/admin/premium/subscription`,
  ADMIN_NOTES: `${config.baseUrl}/admin/notes`,
  PROCESS_PENDING_NOTES: `${config.baseUrl}/admin/notes`,
};
