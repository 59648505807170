import React, { Component } from "react";
import axios from "axios";
import config from "../../config.js";
import Dropdown from "react-bootstrap/Dropdown";
import { adminNotificationSendAllApi } from "../../api/user.js";

class SendPushAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Title: "",
      Text: "",
      Url: "",
      Platform: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);

    this.onTitleChange = this.onTitleChange.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.onUrlChange = this.onUrlChange.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    // axios({
    //     method: 'post',
    //     url: `${config.baseUrl}/admin/notifications/send_all`,
    //     data: {
    //         "title": this.state.Title.toString(),
    //         "text": this.state.Text.toString(),
    //         "url": this.state.Url.toString(),
    //         "platform": this.state.Platform.toString(),
    //     },
    //     headers: {
    //         Authorization: `Bearer ${localStorage.getItem('token')}`
    //     }
    // })
    const body = {
      title: this.state.Title.toString(),
      text: this.state.Text.toString(),
      url: this.state.Url.toString(),
      platform: this.state.Platform.toString(),
    };
    adminNotificationSendAllApi(body)
      .then(function (response) {
        //handle success
        //console.log(response);
        alert("Message Sent.");
        //this.resetForm();
      })
      .catch(function (response) {
        //handle error
        //console.log(response);
        alert("Message failed to send.");
      });
  }

  // handleSubmit1(e){
  //     e.preventDefault();
  //     console.log(this.state.Title.toString())
  //     console.log(this.state.Text.toString())
  //     console.log(this.state.Url.toString())
  //     console.log(this.state.Platform.toString())
  // }
  resetForm() {
    this.setState({
      Title: "",
      Text: "",
      Url: "",
      Platform: "",
    });
  }

  onTitleChange(event) {
    this.setState({ Title: event.target.value });
  }

  onTextChange(event) {
    this.setState({ Text: event.target.value });
  }

  onUrlChange(event) {
    this.setState({ Url: event.target.value });
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <label htmlFor="message" style={{ fontSize: 24 }}>
            Notification for all users:
          </label>
          <div className="form-group">
            <label htmlFor="message" style={{ fontSize: 20 }}>
              Title (visible 30 characters):{" "}
            </label>
            <input
              type="text"
              className="form-control"
              id="Title"
              value={this.state.Title}
              onChange={this.onTitleChange}
            />
          </div>
          <p></p>
          <div className="form-group">
            <label htmlFor="message" style={{ fontSize: 20 }}>
              Text (visible 65 characters):
            </label>
            <textarea
              type="text"
              className="form-control"
              id="Text"
              value={this.state.Text}
              onChange={this.onTextChange}
              style={{}}
              cols="40"
              rows="5"
            />
          </div>
          <div className="form-group">
            <label htmlFor="message" style={{ fontSize: 20 }}>
              Url:
            </label>
            <input
              type="text"
              className="form-control"
              id="Url"
              value={this.state.Url}
              onChange={this.onUrlChange}
            />
          </div>
          <p></p>
          <label htmlFor="message" style={{ fontSize: 20 }}>
            Platform :
          </label>
          <Dropdown>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              style={{ width: "100%" }}
            >
              {this.state.Platform === "" ? "All" : this.state.Platform}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ width: "100%" }}>
              <Dropdown.Item
                href="#/action-1"
                onClick={(e) => this.setState({ Platform: "ios" })}
              >
                ios
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                href="#/action-2"
                onClick={(e) => this.setState({ Platform: "android" })}
              >
                android
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                href="#/action-3"
                onClick={(e) => this.setState({ Platform: "" })}
              >
                All
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <p></p>

          <button
            type="submit"
            className="btn btn-primary"
            style={{ display: "inline-block", width: "100%" }}
          >
            Submit
          </button>
        </form>
      </div>
    );
  }
}

export default SendPushAll;
