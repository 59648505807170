import React, { Component } from "react";
import config from "../../config.js";
import axios from "axios";

import Dropdown from "react-bootstrap/Dropdown";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  textFilter,
  customFilter,
  FILTER_TYPES,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import history from "../../history";
import { languages } from "../../languagesList";
import Button from "@material-ui/core/Button";
import { useStateValue } from "../../store/StateProvider.js";
import { logout } from "../../store/actions/authActions.js";
import {
  adminPremiumSubscriptionApi,
  getAdminUsersApi,
} from "../../api/user.js";

function handleClickUserNotes(rowId, rowName, rowEmail) {
  history.push({
    pathname: "/NoteListUser",
  });
  sessionStorage.setItem("selectedNoteUserId", rowId);
  sessionStorage.setItem("userName", rowName);
  sessionStorage.setItem("userEmail", rowEmail);
  window.location.reload();
}

export class UserList extends Component {
  constructor(props) {
    super(props);
    this.onPageChange = this.onPageChange.bind(this);
    this.onTableChange = this.onTableChange.bind(this);
    this.fetchUser = this.fetchUser.bind(this);
    this.logoutHandler = this.logoutHandler.bind(this);
    this.updateCredits = this.updateCredits.bind(this);
    this.giveCreditsPackage = this.giveCreditsPackage.bind(this);
    this.searchString = "";
    this.state = {
      currentPage: 1,
      users: [],
      totalUsers: 0,
      notesTotalCount: 0,
      columns: [
        {
          dataField: "DateCreated",
          text: "Created at",
          sort: true,
          sortFunc: (a, b, order /*, dataField, rowA, rowB*/) => {
            if (order === "asc") {
              return Date.parse(a) - Date.parse(b);
            } else if (order === "desc") {
              return Date.parse(b) - Date.parse(a);
            }
          },
          headerStyle: () => {
            return { width: "140px", textAlign: "center" };
          },
        },
        {
          dataField: "Name",
          text: "Name ",
          filter: textFilter(),
          // filter: customFilter({
          //   type: FILTER_TYPES.TEXT, // ask react-bootstrap-table to filter data as TEXT
          // }),
          // filterRenderer: (onFilter, column) => (
          //   <SearchBar onApiCall={this.fetchUser} column={column} />
          // ),

          headerStyle: () => {
            return { width: "160px", textAlign: "center" };
          },
        },
        {
          dataField: "Email",
          text: "Email",
          filter: textFilter(),
          // filter: customFilter({
          //   type: FILTER_TYPES.TEXT, // ask react-bootstrap-table to filter data as TEXT
          // }),
          // filterRenderer: (onFilter, column) => (
          //   <SearchBar onApiCall={this.fetchUser} column={column} />
          // ),
          headerStyle: () => {
            return { width: "180px", textAlign: "center" };
          },
          //sort: true,
        },
        {
          dataField: "Languages",
          text: "Languages",
          filter: textFilter(),
          // filter: customFilter({
          //   type: FILTER_TYPES.TEXT, // ask react-bootstrap-table to filter data as TEXT
          // }),
          // filterRenderer: (onFilter, column) => (
          //   <SearchBar onApiCall={this.fetchUser} column={column} />
          // ),
          headerStyle: () => {
            return { width: "120px", textAlign: "center" };
          },
        },
        {
          dataField: "Credits",
          text: "Available Credits",
          sort: true,
          // filter: numberFilter()
          headerStyle: () => {
            return { width: "80px", textAlign: "center" };
          },
        },
        {
          dataField: "",
          headerStyle: () => {
            return { width: "110px", textAlign: "center" };
          },
          text: "Assign Credits",
          formatter: (rowContent, row) => {
            return (
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  Assign
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    href="#/action-1"
                    onClick={(e) =>
                      this.giveCreditsPackage(row.ID, "100per30", e)
                    }
                  >
                    100 for 30 days
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    href="#/action-2"
                    onClick={(e) =>
                      this.giveCreditsPackage(row.ID, "300per90", e)
                    }
                  >
                    300 for 90 days
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    href="#/action-3"
                    onClick={(e) =>
                      this.giveCreditsPackage(row.ID, "600per183", e)
                    }
                  >
                    600 for 183 days
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                      href="#/action-3"
                      onClick={(e) =>
                          this.giveCreditsPackage(row.ID, "1200per365", e)
                      }
                  >
                    1200 for 365 days
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    href="#/action-4"
                    onClick={(e) => this.giveCreditsPackage(row.ID, "trial", e)}
                  >
                    30 Trial
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    href="#/action-4"
                    onClick={(e) =>
                      this.giveCreditsPackage(row.ID, "trial-10per14", e)
                    }
                  >
                    10 Trial
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            );
          },
        },
        {
          dataField: "ExpireIn",
          text: "Expire in (days)",
          sort: true,
          headerStyle: () => {
            return { width: "80px", textAlign: "center" };
          },
        },
        {
          dataField: "SubmitedNotesCount",
          text: "User Notes",
          sort: true,
          headerStyle: () => {
            return { width: "210px", textAlign: "center" };
          },
        },
        {
          dataField: " ",
          text: "View Notes",
          headerStyle: () => {
            return { width: "100px", textAlign: "center" };
          },
          formatter: (rowContent, row) => {
            return (
              <Button
                variant="outlined"
                disabled={!row.SubmitedNotesCount}
                color="primary"
                onClick={(e) =>
                  handleClickUserNotes(row.ID, row.Name, row.Email, e)
                }
              >
                Notes
              </Button>
            );
          },
        },
        {
          dataField: "Platform",
          text: "Platform ",
          filter: textFilter(),
          // filter: customFilter({
          //   type: FILTER_TYPES.TEXT, // ask react-bootstrap-table to filter data as TEXT
          // }),
          // filterRenderer: (onFilter, column) => (
          //   <SearchBar onApiCall={this.fetchUser} column={column} />
          // ),
          headerStyle: () => {
            return { width: "80px", textAlign: "center" };
          },
        },
        {
          dataField: "ID",
          text: "User ID",
          filter: textFilter(),
          // filter: customFilter({
          //   type: FILTER_TYPES.TEXT, // ask react-bootstrap-table to filter data as TEXT
          // }),
          // filterRenderer: (onFilter, column) => (
          //   <SearchBar onApiCall={this.fetchUser} column={column} />
          // ),
          headerStyle: () => {
            return { width: "210px", textAlign: "center" };
          },
        },
      ],
      error: null,
      isLoaded: false,
    };
  }

  giveCreditsPackage(userID, packageID) {
    // axios({
    //   method: "post",
    //   url: `${config.baseUrl}/admin/premium/subscription`,
    //   data: {
    //     user_id: userID.toString(),
    //     package_id: packageID.toString(),
    //   },
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem("token")}`,
    //   },
    // })
    const body = {
      user_id: userID.toString(),
      package_id: packageID.toString(),
    };
    adminPremiumSubscriptionApi(body)
      .then((response) => {
        //handle success
        this.updateCredits(response);
      })
      .catch((response) => {
        //handle error
        console.log(response);
      });
    return alert("Credits assigned");
  }

  updateCredits(response) {
    const updatedUsers = this.state.users.map((user) => {
      if (user.ID === response?.data?.subscription?.user_id) {
        return {
          ...user,
          Credits: user.Credits + response?.data?.subscription?.units_amount,
        };
      }
      return user;
    });
    this.setState({
      users: updatedUsers,
    });
  }

  onPageChange(page) {
    // console.log("ss", page);
    // this.setState({ currentPage: page });
    // this.fetchUser(page, 50, this.searchString);
  }

  logoutHandler = () => {
    localStorage.removeItem("userAdminEmail");
    localStorage.removeItem("token");
    localStorage.removeItem("loginTime");
    sessionStorage.clear();
    this.setState({
      isLoaded: false,
      users: null,
      isAuthenticated: false,
    });
    window.location.reload();
  };

  fetchUser(page, limit, searchString) {
    let queryParam = "";
    queryParam += `?page=${page}&per_page=${limit}&search=${searchString}`;

    getAdminUsersApi(queryParam).then(
      (result) => {
        const updatedUser = this.mapForPage(result.data);
        this.setState({
          isLoaded: true,
          users: [...updatedUser],
          totalUsers: result.total_count,
          // currentPage: result.page + 1,
        });
        this.setState({ currentPage: result.page + 1 });
      },
      (error) => {
        console.log("error", error);
        this.setState({ error });
      }
    );

    // axios({
    //   method: "get",
    //   url: `${config.baseUrl}/admin/users${queryParam}`,
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem("token")}`,
    //   },
    // })
    //   .then((response) => response.data)
    //   .then(
    //     (result) => {
    //       const updatedUser = this.mapForPage(result.data);
    //       this.setState({
    //         isLoaded: true,
    //         users: updatedUser,
    //         totalUsers: result.total_count,
    //       });
    //     },
    //     (error) => {
    //       this.setState({ error });
    //       if (error.response.status === 401) {
    //         console.log("error", error.response.status);
    //         this.logoutHandler();
    //       }
    //     }
    //   );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.totalUsers > 0 &&
      this.state.totalUsers > this.state.users.length &&
      prevState.currentPage !== this.state.currentPage
    ) {
      this.fetchUser(1, this.state.totalUsers, this.searchString);
    }
  }

  componentDidMount() {
    this.fetchUser(1, 50, this.searchString);
  }

  mapForPage(data) {
    return data.map((x) => {
      let credits = (subs) =>
        subs.reduce(
          (prev, current) => prev + (current.units_amount - current.units_used),
          0
        );
      let expiredDateMax = (subs) => {
        let rawDates = subs
          .map((x) => x.date_expired)
          .filter((d) => d !== null);
        if (rawDates.length !== 0) {
          let dates = rawDates.map((x) => new Date(x));
          let maxDate = new Date(Math.max.apply(null, dates));
          return Math.trunc((maxDate - Date.now()) / (60 * 60 * 24 * 1000));
        } else return "";
      };

      return {
        ID: x.id,
        Paid: x.active_subscriptions.length === 0 ? "No" : "Yes",
        Name: x.first_name,
        Email: x.email,
        Languages: x.used_languages
          .map((l) => languages[l])
          .filter((l) => l !== undefined)
          .join(", "),
        IsNew: x.is_new_user ? "Yes" : "No",
        Credits: credits(x.active_subscriptions),
        ExpireIn: expiredDateMax(x.active_subscriptions),
        DateCreated: new Date(x.created_at).toLocaleString(),
        Platform: x.platform,
        SubmitedNotesCount: x.submited_notes_count,
      };
    });
  }

  onTableChange(type, { sortField, sortOrder, data }) {
    setTimeout(() => {
      let result;
      if (sortOrder === "asc") {
        result = data.sort((a, b) => {
          if (a[sortField] > b[sortField]) {
            return 1;
          } else if (b[sortField] > a[sortField]) {
            return -1;
          }
          return 0;
        });
      } else {
        result = data.sort((a, b) => {
          if (a[sortField] > b[sortField]) {
            return -1;
          } else if (b[sortField] > a[sortField]) {
            return 1;
          }
          return 0;
        });
      }
      this.setState(() => ({
        users: result,
      }));
    }, 100);
  }

  render() {
    const options = {
      page: this.state.currentPage,
      sizePerPage: 25,

      // hideSizePerPage: true,
      // hidePageListOnlyOnePage: true,

      totalSize: this.state.totalUsers,

      // paginationSize: 4,
      // pageStartIndex: 1,

      // alwaysShowAllBtns: true, // Always show next and previous button
      // withFirstAndLast: false, // Hide the going to First and Last page button
      //hideSizePerPage: true, // Hide the sizePerPage dropdown always
      // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page

      firstPageText: "First",
      prePageText: "Back",
      nextPageText: "Next",
      lastPageText: "Last",
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      showTotal: true,

      disablePageTitle: true,

      onPageChange: this.onPageChange,

      sizePerPageList: [
        // {
        //    text: '10', value: 10
        // },
        // {
        //    text: '25', value: 25
        // }
        // ,
        // {
        //    text: '100', value: 100
        // }
        // , {
        //     text: 'All', value: products.length
        // }
      ], // A numeric array is also available. the purpose of above example is custom the text
    };

    const { error, isLoaded } = this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div /*className="table-responsive"*/ style={{ marginTop: 0 }}>
          <BootstrapTable
            // scrollY
            // className="table"
            //class="table table-bordered"
            striped
            hover
            condensed
            keyField="ID"
            data={this.state.users}
            columns={this.state.columns}
            filter={filterFactory()}
            remote={{ sort: true }}
            pagination={paginationFactory(options)}
            bootstrap4
            onTableChange={this.onTableChange}
            //wrapperClasses="table-responsive"
            //rowClasses="text-nowrap"
            //cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true }) }
          />
        </div>
      );
    }
  }
}
export default UserList;

export const SearchBar = ({ onApiCall, column }) => {
  const onInputChange = (value) => {
    onApiCall(1, value);
  };
  return (
    <input
      style={{
        width: "100%",
        height: "calc(1.5em + .75rem + 2px)",
        padding: "0.375rem 0.75rem",
        fontSize: "1rem",
        border: "1px solid #ced4da",
        borderRadius: "4px",
      }}
      placeholder={"Enter " + column.dataField}
      type="text"
      onChange={(e) => onInputChange(e.target.value)}
    />
  );
};
