import React from 'react';
import {createUseStyles} from 'react-jss'

const useStyles = createUseStyles({
    note:{
        // width: '50%',
        borderBottom: "1px solid silver",
        padding:"20px",

        '&:hover':{
            cursor:'pointer',
            backgroundColor: 'rgb(211, 217, 223)'
        }
    },
    active:{
        backgroundColor:"rgb(211, 217, 223)"
    }
})

function NoteListItemSubmitted({note, setActive, isActive, isLocked, index}) {
    const classes = useStyles()
    return (
        <div onClick = {() => {setActive(note.id); sessionStorage.setItem('selectedNoteUserId', note.user_id);}}
             className = {classes.note + " " + (isActive? classes.active:"")  } >
            {index}
            { ") "}
            { !isLocked && ((note.text === undefined) ? "" : note.text.substring(0, 25)) }

        </div>
    );
}
export default NoteListItemSubmitted