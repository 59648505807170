/* eslint-disable no-undef */
import axios from "axios";
import config from "../config";

export const axiosClient = axios.create({
  baseURL: config.baseUrl,
  timeout: 30000,
  headers: {
    Accept: "application/json",
  },
});

axiosClient.interceptors.request.use(
  (config) => {
    const hasAccessToken = localStorage.getItem("token");
    hasAccessToken &&
      (config.headers.Authorization = `Bearer ${hasAccessToken}`);
    if (config.data instanceof FormData) {
      config.headers.ContentType = config.data.getHeaders();
    } else {
      config.headers.ContentType = "application/json";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
  { synchronous: true }
);

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data && response.data.type == "application/pdf") {
      return response.data;
    } else if (response.data) {
      return { ...response.data };
    }
    return Promise.reject(response.data);
  },
  (error) => {
    switch (error?.response?.status) {
      case 400:
        return Promise.reject(error.response.data);
      case 401:
        unauthorizeAccess();
        return Promise.reject(error.response.data);
      case 403:
        return Promise.reject(error.response.data);
      case 404:
        return Promise.reject(error.response.data);
      case 405:
        return Promise.reject(error.response.data);
      case 501:
        return Promise.reject(error.response.data);
      case 422:
        return Promise.reject(error.response.data);
      default:
        return Promise.reject(error);
    }
  }
);

function getUrl(config) {
  if (config?.baseURL) {
    let _url = config?.url;
    return _url.replace(config?.baseURL, "");
  }
  return config?.url;
}

const unauthorizeAccess = () => {
  console.log("unauthorizeAccess");
  localStorage.removeItem("userAdminEmail");
  localStorage.removeItem("token");
  localStorage.removeItem("loginTime");
  sessionStorage.clear();
  window.location.reload();
};
