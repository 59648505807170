import config from "../../config.js";
import axios from "axios";
import {
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_ERROR,
  ADMIN_LOGOUT,
  ADMIN_LOGIN_PENDING,
  ADMIN_LOGOUT_ERROR,
} from "./actionTypes";
import { loginApi } from "../../api/user.js";

export function login(email, password) {
  const body = {
    email: email,
    password: password,
  };
  return loginApi(body)
    .then((res) => {
      console.log("Res", res);
      return res.data;
    })
    .then((resJson) => {
      localStorage.setItem("token", resJson.access_token);
      localStorage.setItem("userAdminEmail", resJson.user.email);
      localStorage.setItem("loginTime", new Date().toString());
      return {
        type: ADMIN_LOGIN_SUCCESS,
        payload: {
          user: resJson.user,
        },
      };
    })
    .catch((err) => {
      return {
        type: ADMIN_LOGIN_ERROR,
        payload: {
          errorMessage: err.message || err.statusText,
        },
      };
    });
  //   axios({
  //     method: "post",
  //     url: `${config.baseUrl}/v1/admin/login`,
  //     data: {
  //       email: email,
  //       password: password,
  //     },
  //   })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         return res.data.data;
  //       }
  //       throw res;
  //     })
  //     .then((resJson) => {
  //       localStorage.setItem("token", resJson.access_token);
  //       localStorage.setItem("userAdminEmail", resJson.user.email);
  //       localStorage.setItem("loginTime", new Date().toString());
  //       return {
  //         type: ADMIN_LOGIN_SUCCESS,
  //         payload: {
  //           user: resJson.user,
  //         },
  //       };
  //     })
  //     .catch((err) => {
  //       return {
  //         type: ADMIN_LOGIN_ERROR,
  //         payload: {
  //           errorMessage: err.message || err.statusText,
  //         },
  //       };
  //     });
}

export function loginPending() {
  return {
    type: ADMIN_LOGIN_PENDING,
    payload: {
      pending: true,
    },
  };
}

export function logout() {
  return axios({
    method: "delete",
    url: `${config.baseUrl}/admin/logout`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((res) => {
      if (res.status === 204) {
        return {
          type: ADMIN_LOGOUT,
        };
      }
      throw res;
    })
    .catch((err) => {
      return {
        type: ADMIN_LOGOUT_ERROR,
        payload: {
          errorMessage: err.message || err.statusText,
        },
      };
    });
}
