import React  from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import MobileScreenShareIcon from '@material-ui/icons/MobileScreenShare';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuIcon from '@material-ui/icons/Menu';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import PeopleIcon from '@material-ui/icons/People';
import MailIcon from '@material-ui/icons/Mail';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { Switch, Route, Link, BrowserRouter } from "react-router-dom";
import { useStateValue} from '../../store/StateProvider'
import { logout } from "../../store/actions/authActions"

import UserList from '../userList'
import NoteListPending from '../noteListPending'
import NoteListSubmitted from '../noteListSubmitted'
import NoteInfo from '../noteInfo'
import NoteInfoSubmitted from '../noteInfoSubmitted'
import SendEmail from '../sendEmail'
import SendPush from '../sendPush'
import Dashboard from '../dashboard'
import SendPushAll from "../sendPushAll";
import PreviousUserNotesPending from "../previousUserNotesPending";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    mainArea:{
        display:'flex',
        height: '90vh',
    },
    root: {
        display: 'flex',
    },
    appBar: {

        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {

        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 30,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    title: {
        flexGrow: 1,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding : theme.spacing(0, 0, 0, 0),
    },
}));

export default function MiniDrawer() {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
// LOGOUT
    const [, dispatch] = useStateValue();
    const logoutHandler = async () => {
        dispatch(await logout())
    }
//
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
//

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap className={classes.title}>
                        Command Center
                    </Typography>

                    <Button color="inherit"
                            className={classes.logoutButton}
                            onClick={logoutHandler}>
                        Logout
                    </Button>
                </Toolbar>
            </AppBar>

            <BrowserRouter>

            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
                <Divider />
                <div >
                    <ListItem key={"PendingNotes"} component={Link} to={"/"}>
                        <ListItemIcon>
                                <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary="Pending Notes" />
                    </ListItem>
                    {(
                      (localStorage.getItem('userAdminEmail') ==="operator-1@senstone.io" )
                    ||(localStorage.getItem('userAdminEmail') ==="operator-2@senstone.io" )
                    ||(localStorage.getItem('userAdminEmail') ==="operator-3@senstone.io" )
                    ) && // email based render
                    <>
                        <ListItem key={"UserList"} component={Link} to={"/UserList"}>
                            <ListItemIcon>
                                <PeopleIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Users"/>
                        </ListItem>

                        <ListItem key={"SubmittedNotes"} component={Link} to={"/SubmittedNotes"}>
                            <ListItemIcon>
                                <CheckBoxIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Submitted Notes" />
                        </ListItem>

                        <ListItem  key={"SendPush"} component={Link} to={"/SendPush"} >
                            <ListItemIcon>
                                <SpeakerNotesIcon />
                            </ListItemIcon>
                            <ListItemText primary="Notification" />
                        </ListItem>

                        <ListItem  key={"SendPushAll"} component={Link} to={"/SendPushAll"} >
                            <ListItemIcon>
                                <MobileScreenShareIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Notification for All" />
                        </ListItem>

                        <ListItem  key={"SendEmail"} component={Link} to={"/SendEmail"} >
                            <ListItemIcon>
                                <MailIcon />
                            </ListItemIcon>
                            <ListItemText primary="Email" />
                        </ListItem>
                        </>
                    }
                </div>
            </Drawer>
            <main className={classes.content} >
                <div className={classes.toolbar} />
                <Switch>
                    <Route exact path="/" render={() =>
                        <div className={classes.mainArea}>
                            <NoteListPending/>
                            <div style={{
                                flexDirection : "column",
                                justifyContent : "center",
                                display: "flex"
                            }}>
                                <Dashboard/>
                                <NoteInfo/>
                            </div>
                            <PreviousUserNotesPending/>
                        </div>}
                    />
                    {(
                        (localStorage.getItem('userAdminEmail') ==="operator-1@senstone.io" )
                        ||(localStorage.getItem('userAdminEmail') ==="operator-2@senstone.io" )
                        ||(localStorage.getItem('userAdminEmail') ==="operator-3@senstone.io" )
                    )
                        &&<>
                            <Route path="/UserList"
                                   render={() => <div> <UserList/> </div>}
                            />

                            <Route path="/SubmittedNotes" render={() =>
                                <div className={classes.mainArea}>
                                    <NoteListSubmitted/>
                                    <div style={{
                                        flexDirection : "column",
                                        justifyContent : "center",
                                        display: "flex"
                                    }}>
                                        <Dashboard/>
                                        <NoteInfoSubmitted/>
                                    </div>
                                    <PreviousUserNotesPending/>
                                </div>}
                            />

                            <Route path="/SendPush" render={() => <div> <SendPush/> </div>} />

                            <Route path="/SendPushAll" render={() => <div> <SendPushAll/> </div>} />

                            <Route path="/SendEmail" render={() => <div> <SendEmail/> </div>} />
                        </>
                    }
                </Switch>
            </main>
            </BrowserRouter>
        </div>
    );
}