import React from 'react';
import "./pagination.css";

function Pagination({isDisabledPrev,isDisabledNext, onPrevClick, onNextClick}) {
    return (
        <div className={"Pagination"}>
            <div className='d-flex'>
                <button
                    id='prev-btn'
                    className='pagination-btn'
                    onClick={onPrevClick}
                    disabled={isDisabledPrev}
                >Prev</button>
                <button
                    id='next-btn'
                    className='pagination-btn'
                    onClick={onNextClick}
                    disabled={isDisabledNext}
                >
                    Next
                </button>
            </div>
        </div>
    );
}

export default Pagination