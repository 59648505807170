import React, {useState, useEffect, useRef } from 'react';
import {useStateValue} from '../../store/StateProvider'
import {createUseStyles} from 'react-jss'
import {selectUserNote } from "../../store/actions/noteActions"
import Player from '../player'
import DateTimePicker from 'react-datetime-picker'
import {languages} from "../../languagesList"
import "react-datetime/css/react-datetime.css";

const useStyles = createUseStyles({
    noteInfoPage:{
        width:"100%",
        height:"100%",
        overflow: "auto",
    },
    noteInfo:{
        width:"800px",
        height:"100%",
        overflow: "auto",

    },
    infoHeader:{
        display:"flex",
        justifyContent:"space-between"
    },
    wrapper:{
        width:"400px",
        alignItems: "center",
        backgroundColor:"rgb(255, 255, 255)",

    },

    workArea:{
        textAlign:'center',
    },

    updateButton:{
        width:"180px",
        height: "65px",
        backgroundColor: '#57C58E',
        display: 'inline-block',
        borderRadius: '1px',
        padding:'10px',
        paddingTop:'20px',
        marginBottom: '10px',
        marginTop: "5px",

        '&:hover':{
            cursor: 'pointer',
            backgroundColor: 'green'
        }
    },
    releaseButton:{
        width:"120px",
        height: "41px",
        backgroundColor: '#F3F995',
        fontSize: "12px",
        display: 'inline-block',
        borderRadius: '1px',
        padding: "4px",
        paddingLeft: "12px",
        textAlign : "left",
        marginRight : "85px",
        marginTop: "42px",

        '&:hover':{
            cursor: 'pointer',
            backgroundColor: '#E8FF00'
        }
    },

    textArea:{
        resize: 'none',
        border: '1px solid black',
        margin: '0px 0px'
    },

    statusContainer:{
        minWidth: "200px",
        textAlign: "right",
        height:"60px",
    }
})

function NoteInfoUser() {

    const classes = useStyles()
    const [state, dispatch] = useStateValue();
    const [isReminder, setReminder] = useState(!!state.notes.selectedUserNote.fired_at);
    const textAreaRef = useRef(null);

    if(state.notes.selectedUserNote.fired_at !== null)
    {
        state.notes.selectedUserNote.fired_at = new Date(state.notes.selectedUserNote.fired_at);
    }

    const handleInputChange = event => {
        dispatch(selectUserNote({
                ...state.notes.selectedUserNote,
                text: event.target.value
            })
        )
    };
    const handleTimePicker = date => {
        dispatch(selectUserNote({
                ...state.notes.selectedUserNote,
                fired_at: date
            })
        )
    }

    useEffect(()=> {
        if(state.notes.selectedUserNote.isThatNoteReminder === false ){
            setReminder(false)
        }
    },  [state.notes.selectedUserNote.isThatNoteReminder] )

    useEffect(()=> {
        if(textAreaRef.current){
            textAreaRef.current.focus()
        }
    }, [state.notes.selectedUserNote.id])

    return (
        <div className={classes.noteInfoPage }>
            <div className={classes.noteInfo}>
                {state.notes.selectedUserNote.id ? (
                        <div style={{
                            justifyContent:"space-between",
                            display:"flex",
                        }}>
                            <div className={classes.wrapper}>
                                <div className={classes.workArea}>
                                    <div style={{height:"45px", marginTop: "15px" }}>
                                        <h4>
                                            {state.notes.selectedUserNote.subscription_id === null ? 'Basic' : 'Premium'}
                                        </h4>
                                    </div>
                                    <textarea
                                        height = "300px"
                                        type="text"
                                        value={state.notes.selectedUserNote.text}
                                        onChange={handleInputChange}
                                        name="text"
                                        id="text"
                                        rows="15"
                                        cols="48"
                                        className={classes.textArea}
                                        ref={textAreaRef}
                                    />
                                    <div style={{textAlign: "center", marginLeft: "40px", marginBottom: "10px"}}>
                                        <Player url={state.notes.selectedUserNote.url} />
                                    </div>
                                </div>
                            </div>
                            <div  style={{
                                backgroundColor:"rgb(255, 255, 255)",
                                width:"400px",
                                paddingRight: "15px",
                                alignItems: "center",
                            }}>
                                <div className={classes.statusContainer}>
                                    <h2>
                                        <strong>&ensp;</strong>
                                    </h2>
                                </div>
                                <div style={{
                                    padding: "5px",
                                    height:"305px",
                                    border: '1px solid black',
                                    backgroundColor:"rgb(255, 255, 255)"
                                }}>
                                    <div>
                                        Language: { languages[state.notes.selectedUserNote.language] }
                                    </div>

                                    <div>
                                        Created at: {  new Date (state.notes.selectedUserNote.created_at).toString() }
                                    </div>
                                    <p></p>
                                    <div>
                                        {"Local Timezone: "}
                                        {state.notes.selectedUserNote.record.recorder_at_timezone}
                                    </div>
                                    <p>NB: Convert into local timezone when setting a reminder.</p>
                                    <div>
                                        {"Time selected by reminder: "}
                                        {new Date(state.notes.selectedUserNote.fired_at).getTime() === 0 ? "none":
                                            new Date(state.notes.selectedUserNote.fired_at).toString()}
                                    </div>
                                    <p></p>
                                    <div>
                                        <div style={{display:"inline-block", width:"50%"}}>
                                            <label>
                                                <span style={{paddingLeft:'3px'}} >
                                                    It's "todo": {state.notes.selectedUserNote.is_todo === false ? 'No' : 'Yes'}
                                                </span>
                                            </label>
                                        </div>
                                        <div style={{display:"inline-block", width:"50%"}}>
                                            <div>
                                                <label>
                                                   <span style={{paddingLeft:'3px'}}>
                                                        It's reminder: {state.notes.selectedUserNote.fired_at === null ? 'No' : 'Yes'}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <p></p>
                                    {isReminder &&
                                    <div>
                                        {<DateTimePicker value={state.notes.selectedUserNote.fired_at}
                                                         onChange={handleTimePicker}
                                                         disableClock={true} />}
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                    : null}
            </div>
        </div>
    )
}
export default NoteInfoUser