import React from "react";
import { useStateValue} from '../../store/StateProvider'
import { Redirect } from "react-router-dom";
import {login, loginPending, logout} from "../../store/actions/authActions"
import {createUseStyles} from 'react-jss'


const useStyles = createUseStyles({
    loginContainer:{
        width: '100%',
        height: '100%',
    
        display:'flex',
        justifyContent:'center',
        alignItems:'center',

    },
    container:{
        width:'400px',
        '& h1':{
            textAlign: 'center'
        },
        '& input':{
            border: '1px solid grey',
            borderRadius: '4px',
            height:'30px',
            width: '100%'
        },
        '& form':{
            border: '1px solid black',
            borderRadius: '2px',
            padding: '10px',
            maxWidth: '400px'
        },
        '& form > div':{
            margin:'10px 0px'
        }
    },
    loginButton:{
        backgroundColor:'silver',
        display:'inline-block',
        padding: '5px',
        borderRadius:'4px',
        height:'40px',
        '&:hover':{
            cursor:'pointer'
        }
    }

    

})

export const Login = () => {
    const classes = useStyles()
    const [state, dispatch] = useStateValue(); 
    const initialState = {
        email: "",
        password: "",
    };
    const [data, setData] = React.useState(initialState);
    const handleInputChange = event => {
        setData({
        ...data,
        [event.target.name]: event.target.value
        });
    };

    const handleFormSubmit = async event => {
        event.preventDefault();
        dispatch(loginPending())
        dispatch(await login(data.email, data.password))
        setTimeout( function () {
                dispatch(logout())
        }, 12*60*60*1000);

    };
    return (
        <div className={classes.loginContainer}>
        {state.auth.isAuthenticated ? <Redirect to="/"/>: null}
            <div className={classes.container}>
            <form>
                <h1>Senstone command center</h1>
                <div className={classes.inputRow}>
                
                    <div>Email Address</div>
                    <input
                        type="text"
                        value={data.email}
                        onChange={handleInputChange}
                        name="email"
                        id="email"
                    />
                
                </div>

                <div className={classes.inputRow}>
                <div> Password </div>
                    <input
                        type="password"
                        value={data.password}
                        onChange={handleInputChange}
                        name="password"
                        id="password"
                    />
                </div>

                {state.auth.errorMessage && (
                <div className="form-error">{state.auth.errorMessage}</div>
                )}

            <div className={classes.loginButton} onClick={handleFormSubmit} disabled={state.auth.pending}>
                {state.auth.pending ? (
                    "Loading..."
                ) : (
                    "Login"
                )}
                </div>
            </form>
            </div>
        </div>
    );
};
export default Login;