import config from "../../config.js";
import axios from "axios";
import {
  GET_PENDING_NOTES_SUCCESS,
  GET_PENDING_NOTES_ERROR,
  GET_CHECKED_NOTES_SUCCESS,
  GET_CHECKED_NOTES_ERROR,
  GET_USER_NOTES_SUCCESS,
  GET_USER_NOTES_ERROR,
  SELECT_PENDING_NOTE,
  SELECT_CHECKED_NOTE,
  SELECT_USER_NOTE,
  UPDATE_PENDING_NOTE_SUCCESS,
  UPDATE_PENDING_NOTE_ERROR,
  UPDATE_USER_NOTE_SUCCESS,
  UPDATE_USER_NOTE_ERROR,
  UPDATE_CHECKED_NOTE_SUCCESS,
  UPDATE_CHECKED_NOTE_ERROR,
  LOCK_PENDING_NOTE_SUCCESS,
  LOCK_PENDING_NOTE_ERROR,
  GET_SELECTED_NOTE_USER_INFO,
  GET_SELECTED_NOTE_USER_INFO_ERROR,
  // RELEASE_PENDING_NOTE_SUCCESS,
  PROCESS_PENDING_NOTES_SUCCESS,
  PROCESS_PENDING_NOTES_ERROR,
} from "./actionTypes";
import {
  getAdminNotesApi,
  getAdminNotesPostApi,
  getAdminNotesPutApi,
  getAdminUsersApi,
  processPendingNotesPatchApi
} from "../../api/user.js";

export function getPendingNotes(currentPage = 1, premiumOnly = false) {
  let queryParam = "";
  queryParam += `?enhancing_status=pending&order_by=-created_at&per_page=50&page=${currentPage}`;
  if (premiumOnly) {
    queryParam += "&has_subscription_id=true"
  }
  return getAdminNotesApi(queryParam)
    .then((res) => {
      return {
        type: GET_PENDING_NOTES_SUCCESS,
        payload: {
          pendingNotes: res,
        },
      };
    })
    .catch((err) => {
      return {
        type: GET_PENDING_NOTES_ERROR,
        payload: {
          errorCode: err.response.status,
          errorMessage: err.response.data.message,
        },
      };
    });
}

export function getCheckedNotes(currentPage = 1) {
  let queryParam = "";
  queryParam += `?operator_check=true&order_by=updated_at&per_page=50&page=${currentPage}`;
  return getAdminNotesApi(queryParam)
    .then((res) => {
      return {
        type: GET_CHECKED_NOTES_SUCCESS,
        payload: {
          checkedNotes: res,
        },
      };
    })
    .catch((err) => {
      return {
        type: GET_CHECKED_NOTES_ERROR,
        payload: {
          errorCode: err.response.status,
          errorMessage: err.response.data.message,
        },
      };
    });
}

export function getUserNotes(currentPage, userID) {
  if (!userID) return;
  let queryParam = "";
  queryParam += `?enhancing_status=&user_id=${userID}&per_page=50&page=${currentPage}`;
  return getAdminNotesApi(queryParam)
    .then((res) => {
      return {
        type: GET_USER_NOTES_SUCCESS,
        payload: {
          userNotes: res,
        },
      };
    })
    .catch((err) => {
      return {
        type: GET_USER_NOTES_ERROR,
        payload: {
          errorCode: err.errors[0].code,
          errorMessage: err.errors[0].message,
        },
      };
    });
}

export function selectNote(note) {
  if (!note.text) {
    note.text = "";
  }
  return {
    type: SELECT_PENDING_NOTE,
    payload: {
      note,
    },
  };
}

export function selectCheckedNote(note) {
  if (!note.text) {
    note.text = "";
  }
  return {
    type: SELECT_CHECKED_NOTE,
    payload: {
      note,
    },
  };
}

export function selectUserNote(note) {
  if (!note.text) {
    note.text = "";
  }
  return {
    type: SELECT_USER_NOTE,
    payload: {
      note,
    },
  };
}

export function updatePendingNote(note) {
  let queryParam = "";
  queryParam += `/${note.id}`;
  return getAdminNotesPutApi(queryParam, note)
    .then((res) => {
      return {
        type: UPDATE_PENDING_NOTE_SUCCESS,
        payload: {
          selectedNote: {},
        },
      };
    })
    .catch((err) => {
      return {
        type: UPDATE_PENDING_NOTE_ERROR,
        payload: {
          error: err.message || err.statusText,
          errorCode: err.statusCode,
        },
      };
    });
}

export function updateUserNote(note) {
  let queryParam = "";
  queryParam += `/${note.id}`;
  return getAdminNotesPutApi(queryParam, note)
    .then((res) => {
      return {
        type: UPDATE_USER_NOTE_SUCCESS,
        payload: {
          selectedNote: {},
        },
      };
    })
    .catch((err) => {
      return {
        type: UPDATE_USER_NOTE_ERROR,
        payload: {
          error: err.message || err.statusText,
          errorCode: err.statusCode,
        },
      };
    });
}

export function updateCheckedNote(note) {
  let queryParam = "";
  queryParam += `/${note.id}`;
  return getAdminNotesPutApi(queryParam, note)
    .then((res) => {
      return {
        type: UPDATE_CHECKED_NOTE_SUCCESS,
        payload: {
          selectedCheckedNote: {},
        },
      };
    })
    .catch((err) => {
      return {
        type: UPDATE_CHECKED_NOTE_ERROR,
        payload: {
          error: err.message || err.statusText,
          errorCode: err.statusCode,
        },
      };
    });
}

export function lockAcquirePendingNote(noteID) {
  let queryParam = "";
  queryParam += `/${noteID}/lock/acquire`;
  getAdminNotesPostApi(queryParam)
    .then((res) => {
      return {
        type: LOCK_PENDING_NOTE_SUCCESS,
        payload: {
          selectedNote: res.data,
        },
      };
    })
    .catch((err) => {
      return {
        type: LOCK_PENDING_NOTE_ERROR,
        payload: {
          error: err.message || err.statusText,
          errorCode: err.statusCode,
        },
      };
    });
}

export function lockReleasePendingNote(noteID) {
  let queryParam = "";
  queryParam += `/${noteID}/lock/release`;
  getAdminNotesPostApi(queryParam)
    .then((res) => {
      return {
        type: LOCK_PENDING_NOTE_SUCCESS,
        payload: {
          selectedNote: res.data,
        },
      };
    })
    .catch((err) => {
      return {
        type: LOCK_PENDING_NOTE_ERROR,
        payload: {
          error: err.message || err.statusText,
          errorCode: err.statusCode,
        },
      };
    });
}

export default function getUserInformationForNote() {
  let selectedNoteUserId = sessionStorage.getItem("selectedNoteUserId");
  if (!selectedNoteUserId) return;
  let queryParam = "";
  queryParam += `?id=${selectedNoteUserId}`;
  return getAdminUsersApi(queryParam)
    .then((res) => {
      return {
        type: GET_SELECTED_NOTE_USER_INFO,
        payload: {
          selectedNoteUserInformation: res.data[0],
        },
      };
    })
    .catch((err) => {
      console.log("Error message", err.message);
      console.log("Error statusText", err.statusText);
      console.log("Error code", err.statusCode);
      return {
        type: GET_SELECTED_NOTE_USER_INFO_ERROR,
        payload: {
          error: err.message || err.statusText,
          errorCode: err.statusCode,
        },
      };
    });
}

export function processPendingNotes(notes) {
  return processPendingNotesPatchApi(notes)
    .then((res) => {
      return {
        type: PROCESS_PENDING_NOTES_SUCCESS,
        payload: {
          selectedNote: {},
        },
      };
    })
    .catch((err) => {
      return {
        type: PROCESS_PENDING_NOTES_ERROR,
        payload: {
          error: err.message || err.statusText,
          errorCode: err.statusCode,
        },
      };
    });
}
