import React, { useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss'
import BenzAMRRecorder from 'benz-amr-recorder'
//import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';
import "./player.css";

const useStyles = createUseStyles({
    playerContainer:{
        width: "320px",
        //width: "auto",
        textAlign: 'center'
        //justifyContent:"space-between",
    },
    slider:{
        width: '300px',
        margin: 'auto'
    },
    timer:{
        //textAlign: 'center',
        margin:'5px',
    },
    button: {
        //backgroundColor:'#68A4E7',
        display:'inline-block',
       // padding: '5px',
        //borderRadius:'4px',
        height:'40px',
       // margin:'10px',
        '&:hover':{
            cursor:'pointer',
        },
        minWidth: '320px',
        //textAlign: 'center',
       // border: '1px solid black',
        justifyContent:"space-between",
    },
    controls:{

        display: 'flex',
        //justifyContent: 'center',
        alignItems: 'baseline',
        backgroundColor:'#68A4E7',

    }
})

const Player = ({url}) => {
  
    const classes = useStyles()

    const [player, setPlayer] = useState()
    const [duration, setDuration] = useState(0)
    const [sliderPosition, setSliderPosition] = useState(0)
    const [isPlaying, setPlaying] = useState(false)

    const switchPlay = () => {
        console.log('triggered', isPlaying)
        if (isPlaying) {
            player.promise.then(() => {
                player.recorder.pause()
                setPlaying(false)
            });

        } else {
            player.promise.then(() => {
                setPlaying(true)
                player.recorder.play(sliderPosition)
            });
        }

    }     

    useEffect(() => {
        if  (player){
            player.promise.then(() => setDuration(player.recorder.getDuration()));
        }
    }, [player])

    useEffect(() => {
        if (!isPlaying){
            return 
        }
        const id = setTimeout(()=> setSliderPosition(sliderPosition + 0.1) , 100);
    
        return () => clearTimeout(id);
    }, [sliderPosition, isPlaying]);

    useEffect(() => {
        let recorder, promise
        
        if (url){
            recorder = new BenzAMRRecorder()
            promise = recorder.initWithUrl(url)
            setPlayer( {recorder, promise })
            setSliderPosition(0)
            setDuration(0)
            recorder.onEnded(() => {
                setSliderPosition(0)
                setPlaying(false)
              });
        }

        return () => { promise.then(() => recorder.stop());
        }
    }, [url])

    const onSliderChange = (value) => {
        setSliderPosition(value)
        if (isPlaying) {
            player.promise.then(() => player.recorder.setPosition(value));
        }
    }
    
    return (() => {
        let durationRemainer = duration % 60
        let sliderRemainer = sliderPosition % 60

        let currentTime = (sliderPosition - sliderRemainer) / 60 + ":" + (Math.floor(sliderRemainer) >= 10? Math.floor(sliderRemainer) : "0" + Math.floor(sliderRemainer))
        let formatedDuration = (duration - durationRemainer) / 60 + ":" + (Math.floor(durationRemainer) >= 10? Math.floor(durationRemainer) : "0" + Math.floor(durationRemainer))

        return <div className={classes.playerContainer}>

                    <div className={classes.slider}>
                        <RangeSlider
                            value={sliderPosition}
                            onChange={changeEvent => onSliderChange(+changeEvent.target.value)}
                            min= {0}
                            max= {duration}
                            step= {0.01}
                            variant='primary'
                            className={classes.slider}
                            tooltip='off'
                        />
                    </div>

                    {/* <div className={classes.controls} onClick={switchPlay}>
                       <div
                        className = {classes.button} >
                           <h5>  {`${isPlaying?"Stop":"Play"}`}
                               <p style={{'fontSize': '12px'}}>{currentTime}/{formatedDuration} </p>
                           </h5>
                       </div>
                    </div> */}
                    {/*<div className = {classes.timer}> {currentTime}/{formatedDuration}</div>*/}

                    <button type="button" className="btn btn-primary btn-note-play" onClick={switchPlay}>
                        {`${isPlaying?"Stop":"Play"}`}
                        <br/>
                        <span>{currentTime}/{formatedDuration}</span>
                    </button>

        </div>

    }) ()
    ;
}

export default Player