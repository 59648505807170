import React, { useEffect, useState } from "react";
import { useStateValue } from "../../store/StateProvider";
import { createUseStyles } from "react-jss";
import { logout } from "../../store/actions/authActions";
import getUserInformationForNote, {
  getPendingNotes,
  selectNote,
  lockAcquirePendingNote,
  lockReleasePendingNote,
  processPendingNotes
} from "../../store/actions/noteActions";

import "./noteListPending.css";
import NoteListItem from "../noteListItem";
import Notification from "../notification";
import Multiselect from "multiselect-react-dropdown";
import Pagination from "../pagination/pagination";
window.React = React;

const useStyles = createUseStyles({
  title: {
    alignSelf: "center",
    width: "100px",
  },
  noteList: {
    width: "250px" /* "25%"*/,
    borderRight: "1px solid silver",
    height: "100%",
    overflow: "auto",
  },
  noNotes: {
    alignSelf: "center",
    padding: "20px",
    fontColor: "grey",
  },
  noteMenu: {
    borderBottom: "1px solid silver",
    "& div:not(:first-child)": {
      borderLeft: "1px solid silver",
    },
    "& div": {
      padding: "10px 0px",
      cursor: "pointer",
    },
    "& div.selected": {
      backgroundColor: "rgb(211, 217, 223)",
    },
  },
});

function NoteListPending() {
  const classes = useStyles();
  const initialState = {
    activeNote: null,
    languageOptions: [
      { name: "English US/International", id: 1, shortLanguageName: "en-us" },
      { name: "English British", id: 2, shortLanguageName: "en-gb" },
      { name: "English Australian", id: 3, shortLanguageName: "en-au" },
      { name: "French", id: 4, shortLanguageName: "fr-fr" },
      { name: "German", id: 5, shortLanguageName: "de-de" },
      { name: "Italian", id: 6, shortLanguageName: "it-it" },
      { name: "Japanese", id: 7, shortLanguageName: "ja-jp" },
      { name: "Mandarin(China/Simplified)", id: 8, shortLanguageName: "zh-cn" },
      {
        name: "Mandarin(Taiwan/Traditional)",
        id: 9,
        shortLanguageName: "zh-tw",
      },
      { name: "Polish", id: 10, shortLanguageName: "pl-pl" },
      { name: "Spanish", id: 11, shortLanguageName: "es-es" },
      { name: "Ukrainian", id: 12, shortLanguageName: "uk-ua" },
      { name: "Hebrew (Israel)", id: 13, shortLanguageName: "he-il" },
      { name: "Hindi (India)", id: 14, shortLanguageName: "hi-in" },
      { name: "Arabic", id: 15, shortLanguageName: "ar-ae" },
      { name: "Korean", id: 16, shortLanguageName: "ko-kr" },
      { name: "Portuguese", id: 17, shortLanguageName: "pt-pt" },
      { name: "Russian", id: 18, shortLanguageName: "ru" },
      { name: "Dutch", id: 19, shortLanguageName: "nl-nl" },
    ],
  };

  const [selectedLanguages, setSelectedLanguages] = useState([]);

  const [filteredPendingNotes, setFilterredPendingNotes] = useState([]);

  const [state, dispatch] = useStateValue();

  const [listState, setActiveNote] = useState(initialState);

  const [currentPage, setCurrentPage] = useState(1);

  const [isAllowMultiSelection, setIsAllowMultiSelection] = useState(false);

  const [premiumOnlyEnabled, setPremiumOnlyEnabled] = useState(true);
  
  const isLastPage =
    state.notes.perPage * currentPage >= state.notes.totalCount;

  const onSelectLanguage = (selectedList, selectedItem) => {
    setSelectedLanguages(
      selectedList.map((language) => language.shortLanguageName)
    );
    localStorage.setItem(
      "selectedOperatorLanguages",
      selectedList.map((language) => language.shortLanguageName).toString()
    );
  };

  const onRemoveLanguage = (selectedList, removedItem) => {
    setSelectedLanguages(
      selectedList.map((language) => language.shortLanguageName)
    );
    localStorage.setItem(
      "selectedOperatorLanguages",
      selectedList.map((language) => language.shortLanguageName).toString()
    );
  };

  const fetchData = async () => {
    dispatch(await getUserInformationForNote());
  };

  const fetchPendingNotes = async (currentPage, premiumOnly) => {
    dispatch(await getPendingNotes(currentPage, premiumOnly));
  };

  const setActive = (id) => {
    setActiveNote({
      ...listState,
      activeNote: id,
    });

    let list = state.notes.pendingNotes;
    dispatch(selectNote(list.find((item) => item.id === id)));
  };

  useEffect(() => {
    currentPage && fetchPendingNotes(currentPage, premiumOnlyEnabled);
  }, [currentPage, premiumOnlyEnabled]);

  useEffect(() => {
    let pendingNotes = [];
    if (selectedLanguages.length > 0) {
      pendingNotes = state.notes.pendingNotes.filter((note) =>
        selectedLanguages.includes(note.language)
      );
      setFilterredPendingNotes(pendingNotes);
    } else {
      setFilterredPendingNotes(state.notes.pendingNotes);
    }
  }, [state && state.notes && state.notes.pendingNotes, selectedLanguages]);

  useEffect(() => {
    if (localStorage.getItem("selectedOperatorLanguages")) {
      setTimeout(
        setSelectedLanguages(
          localStorage.getItem("selectedOperatorLanguages").split(",")
        ),
        0
      );
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (state.notes.error.errorCode === 401) {
      console.log("Error 401");
      state.notes.error.errorCode = null;
      state.notes.selectedNote = {};
      dispatch(logout());
    }
  }, [state.notes.error.errorCode]);

  useEffect(() => {
    let id = setInterval(() => {
      fetchPendingNotes(currentPage, premiumOnlyEnabled);
    }, 10000);

    return () => {
      clearInterval(id);
    };
  }, [currentPage, premiumOnlyEnabled]);

  useEffect(() => {
    let id = setInterval(() => {
      fetchData();
    }, 10000);

    return () => {
      clearInterval(id);
    };
  }, []);

  const countLocks = () => {
    let i = 0;
    state.notes.pendingNotes.forEach((entry) => {
      if (entry.is_locked) {
        i++;
      }
    });
    return i;
  };
  const now = Date.now();
  const body = "Please check Command Center.";
  const tag = now;
  const icon =
    "https://mobilusoss.github.io/react-web-notification/example/Notifications_button_24.png";
  const options = {
    tag: tag,
    body: body,
    icon: icon,
    lang: "en",
    dir: "ltr",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let elements = document.getElementById('note-list').elements;
    let checkedNotes = [];
    elements.forEach((item, index) => {
      if(item.checked) {
        checkedNotes.push(item.value);
      }
    });
    if(checkedNotes?.length>0) {
      console.log("checkedNotes", checkedNotes);
      if(window.confirm("Are you sure you want to skip individual processing and save and submit multiple notes?")){
        console.log("CONFIRMED");
        console.log("checkedNotes", checkedNotes);
        setIsAllowMultiSelection(false);
        dispatch(await processPendingNotes({"notes": checkedNotes}));
        dispatch(await getPendingNotes());
      } else {
        console.log("CANCEL");
      }
    }
  };
  
  const enableMultipleNotes = () => {
    console.log("enableMultipleNotes", isAllowMultiSelection);
    setIsAllowMultiSelection(!isAllowMultiSelection);
  };

  const togglePremiumOnly = () => {
    setPremiumOnlyEnabled(!premiumOnlyEnabled)
  }

  return (() => {
    let list = (
      <div style={{ overflow: "auto" }}>
        {filteredPendingNotes &&
          filteredPendingNotes.length > 0 &&
          filteredPendingNotes.map((item, index) => (
            <NoteListItem
              isActive={item.id === listState.activeNote}
              isAnyNoteActive={state.notes.selectedNote.id}
              index={index + 1}
              key={item.id}
              note={item}
              isLocked={item.is_locked}
              setActive={setActive}
              lockAcquirePendingNote={lockAcquirePendingNote}
              lockReleasePendingNote={lockReleasePendingNote}
              noteStatus={item.status}
              isAllowMultiSelection={isAllowMultiSelection}
            />
          ))}
        {!state.notes.pendingNotes.length && (
          <div className={classes.noNotes}>No pending notes</div>
        )}
      </div>
    );

    return (
      <div className={classes.noteList}>
        <div>
          <div>
            <div>
              <div
                className={" text-center"}
                style={{ fontSize: "16px", marginTop: "5px" }}
              >
                Pending notes
              </div>

              <div
                  className={" text-center"}
                  style={{
                    fontSize: "11px",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
              >
                <button className="multi-selection-btn" onClick={togglePremiumOnly} >
                  {!premiumOnlyEnabled ? "Premium notes" : "All notes"}
                </button>
                {state?.notes?.pendingNotes &&
                state?.notes?.pendingNotes.length > 0 && (
                  <button className="multi-selection-btn" onClick={enableMultipleNotes}
                  >{!isAllowMultiSelection ? "Multiple" : "Single"}</button>
                )}

                <label>
                  Remaining:{" "}
                  {filteredPendingNotes &&
                      filteredPendingNotes.length > 0 &&
                      filteredPendingNotes.length}
                </label>
                <label>
                  {filteredPendingNotes &&
                      filteredPendingNotes.length > 0 &&
                      state.notes.selectedNote.id === undefined &&
                      countLocks() < filteredPendingNotes &&
                      filteredPendingNotes.length && (
                          <>
                            <Notification
                                timeout={4000}
                                title={"You have new pending note(s)"}
                                options={options}
                            />
                          </>
                      )}
                </label>
              </div>
              <Multiselect
                  options={initialState.languageOptions}
                  selectedValues={initialState.languageOptions.filter(
                      (languages) =>
                          selectedLanguages.includes(languages.shortLanguageName)
                )}
                onKeyPressFn={function noRefCheck() {}}
                onRemove={onRemoveLanguage}
                onSearch={function noRefCheck() {}}
                showCheckbox="true"
                onSelect={onSelectLanguage}
                displayValue="name"
                placeholder="Select Language"
              />
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit} id="note-list">

          {list}
          
          {(isAllowMultiSelection) && (
          <div className="save-and-submit">
            <button 
            className="btn btn-primary" 
            type="submit">
              Save & Submit
            </button>
          </div>
          )}
          
        </form>

        {state?.notes?.pendingNotes &&
          state?.notes?.pendingNotes.length > 0 && (
            <Pagination
              onPrevClick={() =>
                setCurrentPage((currentPage) => currentPage - 1)
              }
              onNextClick={() =>
                setCurrentPage((currentPage) => currentPage + 1)
              }
              isDisabledPrev={currentPage == 1}
              isDisabledNext={isLastPage}
            />
          )}
      </div>
    );
  })();
}

export default NoteListPending;
