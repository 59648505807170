import React from 'react';
import {createUseStyles} from 'react-jss'

const useStyles = createUseStyles({
    note:{
        // width: '50%',
        borderBottom: "1px solid silver",
        padding:"20px",

        '&:hover':{
            cursor:'pointer',
            backgroundColor: 'rgb(211, 217, 223)'
        }
    },
    active:{
        backgroundColor:"rgb(211, 217, 223)"
    }
})

function NoteListItemPrevious({note, setActive, isActive, isLocked, index}) {
    const classes = useStyles()
    return (
        <div
            className = {classes.note + " " + (isActive? classes.active:"")  } >
            {index}
            { ") "}
            <br/>
            {/*{ !isLocked && ((note.text === undefined) ? "" : note.text) }*/}
            <textarea
                height = "200px"
                type="text"
                value={note.text}
                //onChange={handleInputChange}
                name="text"
                id="text"
                rows="15"
                cols="39"
                readOnly
                //className={classes.textArea}
                //ref={textAreaRef}
            />
        </div>
    );
}
export default NoteListItemPrevious