export const languages = {
    'en-us': 'English US/International',
    "en-gb": 'English British',
    "en-au": 'English Australian',
    'uk-ua': 'Ukrainian',
    'ja-jp': 'Japanese',
    'ar-ae': 'Arabic',
    'fr-fr': 'French',
    'de-de': 'German',
    'it-it': 'Italian',
    'zh-cn': 'Mandarin (China/Simplifies)',
    'zh-tw': 'Mandarin (Taiwan/Traditional)',
    'pl-pl': 'Polish',
    "he-il": 'Hebrew (Israel)',
    "hi-in": 'Hindi (India)',
    "pt-pt": 'Portuguese',
    "nl-nl": 'Dutch',
    'es-es': 'Spanish',
    'ko-kr': 'Korean',
    'ru': 'Russian',
};