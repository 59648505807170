import React from 'react';
import {createUseStyles} from 'react-jss'
import MiniDrawer from '../miniDrawer'


const useStyles = createUseStyles({
    mainArea:{
        display:'flex',
       // height: '110vh',
        //overflow: 'hidden'
    }
})

function HomePage() {
   const classes = useStyles()

    return (
        <div className={classes.mainArea}>
                <MiniDrawer/>
        </div>
    );
}

export default HomePage