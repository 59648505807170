import React, { Component } from "react";
import axios from "axios";
import config from "../../config.js";
import { adminNotificationSendApi } from "../../api/user.js";

class SendPush extends Component {
  constructor(props) {
    super(props);

    this.state = {
      UserID: "",
      Title: "",
      Text: "",
      Url: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);

    this.onUserIDChange = this.onUserIDChange.bind(this);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.onUrlChange = this.onUrlChange.bind(this);
  }
  handleSubmit(e) {
    e.preventDefault();

    // axios({
    //   method: "post",
    //   url: `${config.baseUrl}/admin/notifications/send`,
    //   data: {
    //     user_id: this.state.UserID.toString(),
    //     title: this.state.Title.toString(),
    //     text: this.state.Text.toString(),
    //     url: this.state.Url.toString(),
    //   },
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem("token")}`,
    //   },
    // })
    const body = {
      user_id: this.state.UserID.toString(),
      title: this.state.Title.toString(),
      text: this.state.Text.toString(),
      url: this.state.Url.toString(),
    };
    adminNotificationSendApi(body)
      .then(function (response) {
        //handle success
        console.log(response);
        alert("Message Sent.");
        //this.resetForm();
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        alert("Message failed to send.");
      });
  }
  resetForm() {
    this.setState({
      Title: "",
      Text: "",
      UserID: "",
    });
  }

  onUserIDChange(event) {
    this.setState({ UserID: event.target.value });
  }

  onTitleChange(event) {
    this.setState({ Title: event.target.value });
  }

  onTextChange(event) {
    this.setState({ Text: event.target.value });
  }

  onUrlChange(event) {
    this.setState({ Url: event.target.value });
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <label htmlFor="message" style={{ fontSize: 24 }}>
            Personalized Notification:
          </label>

          <div className="form-group">
            <label htmlFor="message" style={{ fontSize: 20 }}>
              User ID (no spaces):
            </label>
            <input
              type="text"
              className="form-control"
              value={this.state.UserID}
              onChange={this.onUserIDChange}
            />
          </div>
          <p></p>
          <div className="form-group">
            <label htmlFor="message" style={{ fontSize: 20 }}>
              Title (visible 30 characters):{" "}
            </label>
            <input
              type="text"
              className="form-control"
              value={this.state.Title}
              onChange={this.onTitleChange}
            />
          </div>
          <p></p>
          <div className="form-group">
            <label htmlFor="message" style={{ fontSize: 20 }}>
              Text (visible 65 characters):{" "}
            </label>
            <textarea
              type="text"
              className="form-control"
              value={this.state.Text}
              onChange={this.onTextChange}
              style={{}}
              cols="40"
              rows="5"
            />
          </div>
          <p></p>
          <div className="form-group">
            <label htmlFor="message" style={{ fontSize: 20 }}>
              Url:{" "}
            </label>
            <input
              type="text"
              className="form-control"
              value={this.state.Url}
              onChange={this.onUrlChange}
            />
          </div>

          <button
            type="submit"
            className="btn btn-primary"
            style={{ display: "inline-block", width: "100%" }}
          >
            Submit
          </button>
        </form>
      </div>
    );
  }
}

export default SendPush;
