import React, { Component } from "react";
import CKEditor from "ckeditor4-react";
import axios from "axios";
import config from "../../config.js";
import { adminEmailAllApi, adminEmailApi } from "../../api/user.js";

class SendEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UserIDs: "",
      EmailSubject: "",
      EmailHTML: "",
      sendEmailToAllUsers: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onUserIDsChange = this.onUserIDsChange.bind(this);
    this.onEmailSubjectChange = this.onEmailSubjectChange.bind(this);
    this.onEmailHTMLChange = this.onEmailHTMLChange.bind(this);
    this.onSendEmailToAllUsersChange =
      this.onSendEmailToAllUsersChange.bind(this);
  }
  handleSubmit(e) {
    e.preventDefault();
    if (this.state.sendEmailToAllUsers) {
      // axios({
      //   method: "post",
      //   url: `${config.baseUrl}/admin/email/send_all`,
      //   data: {
      //     subject: this.state.EmailSubject.toString(),
      //     html: this.state.EmailHTML.toString(),
      //   },
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem("token")}`,
      //   },
      // })
      const body = {
        subject: this.state.EmailSubject.toString(),
        html: this.state.EmailHTML.toString(),
      };
      adminEmailAllApi(body)
        .then(function (response) {
          console.log(response);
          alert("Message sent to all users.");
        })
        .catch(function (response) {
          console.log(response);
          alert("Message failed to send to all users.");
        });
    } else {
      // axios({
      //   method: "post",
      //   url: `${config.baseUrl}/admin/email/send`,
      //   data: {
      //     user_ids: this.state.UserIDs,
      //     subject: this.state.EmailSubject.toString(),
      //     html: this.state.EmailHTML.toString(),
      //   },
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem("token")}`,
      //   },
      // });
      const body = {
        user_ids: this.state.UserIDs,
        subject: this.state.EmailSubject.toString(),
        html: this.state.EmailHTML.toString(),
      };
      adminEmailApi(body)
        .then(function (response) {
          console.log(response);
          alert("Message Sent.");
          Location.reload();
        })
        .catch(function (response) {
          console.log(response);
          alert("Message failed to send.");
        });
    }
  }

  onUserIDsChange(event) {
    this.setState({ UserIDs: new Array(event.target.value) });
  }
  onEmailSubjectChange(event) {
    this.setState({ EmailSubject: event.target.value });
  }
  onEmailHTMLChange(event) {
    this.setState({ EmailHTML: event.editor.getData() });
  }
  onSendEmailToAllUsersChange = (event) => {
    this.setState({ sendEmailToAllUsers: !this.state.sendEmailToAllUsers });
  };

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <label htmlFor="message" style={{ fontSize: 24 }}>
            Personalized Email:
          </label>
          {!this.state.sendEmailToAllUsers && (
            <div className="form-group">
              <label htmlFor="message" style={{ fontSize: 20 }}>
                User ID (no spaces):
              </label>
              <input
                type="text"
                className="form-control"
                id="UserIDs"
                value={this.state.UserIDs}
                onChange={this.onUserIDsChange}
              />
            </div>
          )}
          <p></p>
          <div className="form-group">
            <label htmlFor="message" style={{ fontSize: 20 }}>
              Subject (consice and to the point):
            </label>
            <input
              type="text"
              className="form-control"
              id="EmailSubject"
              value={this.state.EmailSubject}
              onChange={this.onEmailSubjectChange}
            />
          </div>
          <p></p>
          <div className="form-group">
            <label htmlFor="message" style={{ fontSize: 20 }}>
              Body Mail:
            </label>

            <div className="editor-instance">
              <CKEditor
                id="EmailHTML"
                data={this.state.EmailHTML}
                onChange={this.onEmailHTMLChange}
              />
            </div>
          </div>
          <div>
            <input
              type="checkbox"
              checked={this.state.sendEmailToAllUsers}
              onChange={this.onSendEmailToAllUsersChange}
              style={{
                marginRight: "0.2rem",
                marginLeft: "0.5rem",
                zoom: 1.2,
              }}
            />
            <label style={{ fontSize: 20 }}>Send email to all users</label>
          </div>
          <button
            type="submit"
            className="btn btn-primary"
            style={{
              display: "inline-block",
              width: "100%",
            }}
          >
            Submit
          </button>
        </form>
      </div>
    );
  }
}

export default SendEmail;
