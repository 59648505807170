import {
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_ERROR,
  ADMIN_LOGOUT,
  ADMIN_LOGIN_PENDING,
} from "../actions/actionTypes";
const reducer = (state, action) => {
  switch (action?.type) {
    case ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        pending: false,
        errorMessage: null,
      };
    case ADMIN_LOGOUT:
      //localStorage.clear();
      localStorage.removeItem("userAdminEmail");
      localStorage.removeItem("token");
      localStorage.removeItem("loginTime");
      sessionStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    case "SET_USER": {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    }
    case ADMIN_LOGIN_ERROR: {
      return {
        ...state,
        isAuthenticated: false,
        errorMessage: action.payload.errorMessage,
        pending: false,
      };
    }
    case ADMIN_LOGIN_PENDING: {
      return {
        ...state,
        pending: true,
        errorMessage: null,
      };
    }

    default:
      return state;
  }
};

export default reducer;
