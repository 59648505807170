import React from 'react';
import Main from '../main/main.js'
import { StateProvider } from '../../store/StateProvider';
import mainReducer from '../../store/reducers/mainReducer'

import injectSheet from 'react-jss';
import 'bootstrap/dist/css/bootstrap.min.css';

const style = {
  '@global': {
      body: {
          margin: 0,
          fontFamily: ` -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;`,
          height:"100%",
          padding:0,
      },
      html:{
        height:"100%",
        padding:"0",
        margin:"0",
      },
      "#root":{
        height:"100%",
        padding:"0",
        margin:"0",
      },
      ".App":{
        height:"100%",
        padding:"0",
        margin:"0",
      }
  }
};



function App() {
  let token = localStorage.getItem('token')
  //let userAdminEmail = localStorage.getItem('userAdminEmail')
  const initialState = {
    auth:{
      isAuthenticated: !!token,
      user: null,
      token: null,
    },
    notes:{
      pendingNotes: [],
      selectedNote: {},
      isThatNoteReminder: false,

      checkedNotes: [],
      selectedCheckedNote: {},

      userNotes: [],
      selectedUserNote: {},

      selectedNoteUserInformation: {},
      error:{
        errorCode:null,
        errorText:null
      }
    }
  };
  
  return (
      <StateProvider initialState={initialState} reducer={mainReducer}>
        <Main/>
      </StateProvider>
  );
}

export default injectSheet(style)(App);
