import React, { useEffect, useState } from 'react';
import {createUseStyles} from 'react-jss'
import {useStateValue} from '../../store/StateProvider'

const useStyles = createUseStyles({
    alert:{
        backgroundColor: "pink"
    },
    timer:{
        display:"inline-block",
        padding:"0px 10px"
    },
    timerAlert: {
        composes:["$timer", "$alert"]
    }
})

const INIT_TIME = 60 * 3

const Timer = () => {
  
    const classes = useStyles()

    const [alarm, setAlarm] = useState(false)
    const [seconds, setSeconds] = useState()
    const [state] = useStateValue();

    useEffect(() => {
        if (seconds === 0) {
            return
        }
        const id = setTimeout(() => {
            setSeconds(seconds - 1);
          }, 1000);

          return () => clearTimeout(id);
        }, [seconds]);
    
    useEffect(() => {
        setSeconds(INIT_TIME)
        }, [state.notes.selectedNote.id]);
    
    useEffect(() => {
        var id
        if (seconds === 0){
            id = setTimeout(() => {
                setAlarm(!alarm);
              }, 500);
        } else {
                setAlarm(false)
        }
            return () => clearTimeout(id);
    }, [seconds, alarm])
    
    return (() => {
        let remainer = seconds % 60
        return <div className={alarm?classes.timerAlert:classes.timer}>
                    <h1>{ (seconds - remainer) / 60 + ":" + (remainer >= 10? remainer : "0" + remainer) }</h1>
                </div>
    })()
}

export default Timer