export const ADMIN_LOGIN_SUCCESS = 'LOGIN'
export const ADMIN_LOGIN_ERROR= 'LOGIN_ERROR'
export const ADMIN_LOGIN_PENDING = 'LOGIN_PENDING'

export const ADMIN_LOGOUT = 'LOGOUT'
export const ADMIN_LOGOUT_ERROR = 'LOGOUT ERROR'

export const GET_PENDING_NOTES_SUCCESS = 'GET_PENDING_NOTES_SUCCESS'
export const GET_PENDING_NOTES_ERROR = 'GET_PENDING_NOTES_ERROR'
export const GET_PENDING_NOTES_PENDING = 'GET_PENDING_NOTES_PENDING'

export const GET_CHECKED_NOTES_SUCCESS = "GET_CHECKED_NOTES_SUCCESS"
export const GET_CHECKED_NOTES_ERROR = 'GET_CHECKED_NOTES_ERROR'

export const GET_USER_NOTES_SUCCESS = "GET_USER_NOTES_SUCCESS"
export const GET_USER_NOTES_ERROR = 'GET_USER_NOTES_ERROR'

export const SELECT_PENDING_NOTE = "SELECT_PENDING_NOTE"
export const SELECT_CHECKED_NOTE = "SELECT_CHECKED_NOTE"
export const SELECT_USER_NOTE = "SELECT_USER_NOTE"

export const UPDATE_PENDING_NOTE_SUCCESS = 'UPDATE_PENDING_NOTE_SUCCESS'
export const UPDATE_PENDING_NOTE_ERROR = 'UPDATE_PENDING_NOTE_ERROR'
export const UPDATE_PENDING_NOTE_PENDING = 'UPDATE_PENDING_NOTE_PENDING'

export const UPDATE_USER_NOTE_SUCCESS = 'UPDATE_USER_NOTE_SUCCESS'
export const UPDATE_USER_NOTE_ERROR = 'UPDATE_USER_NOTE_ERROR'

export const UPDATE_CHECKED_NOTE_SUCCESS = 'UPDATE_CHECKED_NOTE_SUCCESS'
export const UPDATE_CHECKED_NOTE_ERROR = 'UPDATE_CHECKED_NOTE_ERROR'

export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"

export const LOCK_PENDING_NOTE_SUCCESS = 'LOCK_PENDING_NOTE_SUCCESS'
export const LOCK_PENDING_NOTE_ERROR = 'LOCK_PENDING_NOTE_ERROR'

export const GET_SELECTED_NOTE_USER_INFO = 'GET_SELECTED_NOTE_USER_INFO'
export const GET_SELECTED_NOTE_USER_INFO_ERROR = 'GET_SELECTED_NOTE_USER_INFO_ERROR'

export const RELEASE_PENDING_NOTE_ERROR = 'RELEASE_PENDING_NOTE_SUCCESS'

export const PROCESS_PENDING_NOTES_SUCCESS = 'PROCESS_PENDING_NOTES_SUCCESS'
export const PROCESS_PENDING_NOTES_ERROR = 'PROCESS_PENDING_NOTES_ERROR'