import {
  GET_PENDING_NOTES_SUCCESS,
  //GET_PENDING_NOTES_ERROR,
  SELECT_PENDING_NOTE,
  SELECT_USER_NOTE,
  UPDATE_PENDING_NOTE_SUCCESS,
  UPDATE_USER_NOTE_SUCCESS,
  UPDATE_CHECKED_NOTE_SUCCESS,
  GET_CHECKED_NOTES_SUCCESS,
  GET_USER_NOTES_SUCCESS,
  GET_SELECTED_NOTE_USER_INFO,
  SELECT_CHECKED_NOTE,
  // GET_USER_NOTES_ERROR,
} from "../actions/actionTypes";

const reducer = (state, action) => {
  switch (action?.type) {
    case GET_PENDING_NOTES_SUCCESS: {
      return {
        ...state,
        pendingNotes: action.payload.pendingNotes?.notes || [],
        perPage: action.payload.pendingNotes?.per_page || 50,
        totalCount: action.payload.pendingNotes?.total_count,
        error: {
          errorCode: null,
          errorText: null,
        },
      };
    }
    case GET_CHECKED_NOTES_SUCCESS: {
      return {
        ...state,
        checkedNotes: action.payload.checkedNotes?.notes || [],
        perPage: action.payload.checkedNotes?.per_page || 50,
        totalCount: action.payload.checkedNotes?.total_count,
        error: {
          errorCode: null,
          errorText: null,
        },
      };
    }
    case GET_USER_NOTES_SUCCESS: {
      return {
        ...state,
        userNotes: action.payload.userNotes?.notes || [],
        perPage: action.payload.userNotes?.per_page || 50,
        totalCount: action.payload.userNotes?.total_count,
        error: {
          errorCode: null,
          errorText: null,
        },
      };
    }
    case SELECT_PENDING_NOTE: {
      return {
        ...state,
        selectedNote: action.payload.note,
      };
    }
    case SELECT_USER_NOTE: {
      return {
        ...state,
        selectedUserNote: action.payload.note,
      };
    }
    case SELECT_CHECKED_NOTE: {
      return {
        ...state,
        selectedCheckedNote: action.payload.note,
      };
    }
    case UPDATE_PENDING_NOTE_SUCCESS: {
      return {
        ...state,
        selectedNote: {},
      };
    }
    case UPDATE_USER_NOTE_SUCCESS: {
      return {
        ...state,
        selectedUserNote: {},
      };
    }
    case UPDATE_CHECKED_NOTE_SUCCESS: {
      return {
        ...state,
        selectedCheckedNote: {},
      };
    }
    case GET_SELECTED_NOTE_USER_INFO: {
      return {
        ...state,
        selectedNoteUserInformation: action.payload.selectedNoteUserInformation,
      };
    }
    default:
      return state;
  }
};
export default reducer;
