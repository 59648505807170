import React, { useState, useEffect, useRef } from "react";
import { useStateValue } from "../../store/StateProvider";
import { createUseStyles } from "react-jss";
import {
  selectNote,
  updatePendingNote,
  getPendingNotes,
  lockReleasePendingNote,
} from "../../store/actions/noteActions";
import Timer from "../timer";
import Player from "../player";
import DateTimePicker from "react-datetime-picker";
import { languages } from "../../languagesList";
import "react-datetime/css/react-datetime.css";
import "./noteInfo.css";

const useStyles = createUseStyles({
  noteInfoPage: {
    width: "100%",
    height: "100%",
    overflow: "auto",
  },
  noteInfo: {
    width: "800px",
    height: "100%",
    overflow: "auto",
  },
  infoHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  wrapper: {
    width: "400px",
    alignItems: "center",
    backgroundColor: "rgb(255, 255, 255)",
  },

  workArea: {
    textAlign: "center",
  },

  updateButton: {
    width: "180px",
    height: "65px",
    backgroundColor: "#57C58E",
    display: "inline-block",
    borderRadius: "1px",
    padding: "10px",
    paddingTop: "20px",
    marginBottom: "10px",
    marginTop: "5px",

    "&:hover": {
      cursor: "pointer",
      backgroundColor: "green",
    },
  },
  releaseButton: {
    width: "120px",
    height: "41px",
    backgroundColor: "#F3F995",
    fontSize: "12px",
    display: "inline-block",
    borderRadius: "1px",
    padding: "4px",
    paddingLeft: "12px",
    textAlign: "left",
    marginRight: "85px",
    marginTop: "42px",

    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#E8FF00",
    },
  },

  textArea: {
    resize: "none",
    border: "1px solid black",
    margin: "0px 0px",
  },

  statusContainer: {
    minWidth: "200px",
    textAlign: "right",
    height: "60px",
  },
});

function NoteInfo() {
  const classes = useStyles();
  const [state, dispatch] = useStateValue();
  const [isReminder, setReminder] = useState(
    !!state.notes.selectedNote.fired_at
  );
  const textAreaRef = useRef(null);

  if (state.notes.selectedNote.fired_at !== null) {
    state.notes.selectedNote.fired_at = new Date(
      state.notes.selectedNote.fired_at
    );
  }

  const handleInputChange = (event) => {
    dispatch(
      selectNote({
        ...state.notes.selectedNote,
        text: event.target.value,
      })
    );
  };

  const handleTodoChange = (event) => {
    dispatch(
      selectNote({
        ...state.notes.selectedNote,
        is_todo: !state.notes.selectedNote.is_todo,
      })
    );
  };

  const updateNote = async () => {
    dispatch(await updatePendingNote(state.notes.selectedNote));
    dispatch(await lockReleasePendingNote(state.notes.selectedNote.id));
    state.notes.selectedNote = {};
    state.notes.selectedNote.isThatNoteReminder = false;
    dispatch(await getPendingNotes());
  };

  const releaseNote = async () => {
    dispatch(await lockReleasePendingNote(state.notes.selectedNote.id));
    state.notes.selectedNote = {};
    state.notes.selectedNote.isThatNoteReminder = false;
    dispatch(await getPendingNotes());
  };

  const updateReminder = () => {
    let newReminder = !isReminder;
    if (!newReminder) {
      dispatch(
        selectNote({
          ...state.notes.selectedNote,
          is_todo: !state.notes.selectedNote.is_todo,
        })
      );
      dispatch(
        selectNote({
          ...state.notes.selectedNote,
          fired_at: null,
        })
      );
      setReminder(newReminder);
      state.notes.selectedNote.isThatNoteReminder = newReminder;
    } else {
      setReminder(newReminder);
      state.notes.selectedNote.isThatNoteReminder = newReminder;
    }
  };

  const handleTimePicker = (date) => {
    dispatch(
      selectNote({
        ...state.notes.selectedNote,
        fired_at: date,
      })
    );
  };

  const renderUserInfo = (user) => {
    if (
      localStorage.getItem("userAdminEmail") === "operator-18@senstone.io" ||
      localStorage.getItem("userAdminEmail") === "operator-19@senstone.io" ||
      localStorage.getItem("userAdminEmail") === "operator-20@senstone.io"
    ) {
      return "Access level required";
    } else {
      return user;
    }
  };

  useEffect(() => {
    if (state.notes.selectedNote.isThatNoteReminder === false) {
      setReminder(false);
    }
  }, [state.notes.selectedNote.isThatNoteReminder]);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [state.notes.selectedNote.id]);

  return (
    <div className={classes.noteInfoPage}>
      <div className={classes.noteInfo}>
        {state.notes.selectedNote.id ? (
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <div className={classes.wrapper}>
              <div className={classes.workArea}>
                <div style={{ height: "45px", marginTop: "15px" }}>
                  {state.notes.selectedNote.subscription_id === null ? (
                    <h4>Basic</h4>
                  ) : (
                    <h4>Premium</h4>
                  )}
                </div>
                <textarea
                  height="300px"
                  type="text"
                  value={state.notes.selectedNote.text}
                  onChange={handleInputChange}
                  name="text"
                  id="text"
                  rows="15"
                  cols="48"
                  className={classes.textArea}
                  ref={textAreaRef}
                />
                <div
                  style={{
                    textAlign: "center",
                    marginLeft: "40px",
                    marginBottom: "10px",
                  }}
                >
                  <Player url={state.notes.selectedNote.url} />
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundColor: "rgb(255, 255, 255)",
                width: "400px",
                paddingRight: "15px",
                alignItems: "center",
              }}
            >
              <div className={classes.statusContainer}>
                <Timer />
              </div>
              <div
                style={{
                  padding: "5px",
                  height: "305px",
                  border: "1px solid black",
                  backgroundColor: "rgb(255, 255, 255)",
                }}
              >
                <div>
                  Language: {languages[state.notes.selectedNote.language]}
                </div>

                <div>
                  Created at:{" "}
                  {new Date(state.notes.selectedNote.created_at).toString()}
                </div>
                <div>
                  {"Local Timezone: "}
                  {state.notes.selectedNote.record.recorder_at_timezone}
                  <br />
                  <br />
                  {"User Name: "}
                  {renderUserInfo(
                    state.notes.selectedNoteUserInformation.first_name
                  )}
                  <br />
                  {"User Email: "}
                  {renderUserInfo(
                    state.notes.selectedNoteUserInformation.email
                  )}
                </div>
                <p>NB: Convert into local timezone when setting a reminder.</p>
                <div>
                  {"Time selected by reminder: "}
                  {/* `${state.notes.selectedNote.fired_at === 0 ? "None": "Play"}`*/}
                  {new Date(state.notes.selectedNote.fired_at).getTime() === 0
                    ? "none"
                    : new Date(state.notes.selectedNote.fired_at).toString()}
                </div>
                <p></p>

                <div>
                  <div style={{ display: "inline-block", width: "50%" }}>
                    <label>
                      <input
                        type="checkbox"
                        name="todo"
                        checked={state.notes.selectedNote.is_todo}
                        onChange={handleTodoChange}
                      />
                      <span style={{ paddingLeft: "3px" }}>Mark as "todo"</span>
                    </label>
                  </div>
                  <div style={{ display: "inline-block", width: "50%" }}>
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          name="todo"
                          defaultChecked={false}
                          //checked={isReminder}
                          onChange={updateReminder}
                        />
                        <span style={{ paddingLeft: "3px" }}>
                          {" "}
                          Add reminder
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <p></p>
                {isReminder && (
                  <div>
                    {
                      <DateTimePicker
                        value={state.notes.selectedNote.fired_at}
                        onChange={handleTimePicker}
                        disableClock={true}
                      />
                    }
                  </div>
                )}
              </div>

              <div style={{ textAlign: "right", marginTop: "45px" }}>
                
                {/* classes.releaseButton */}
                {/* <div className={"btn btn-warning"} onClick={releaseNote}>
                  <p>Stop Editing and Unlock This Note</p>
                </div> */}

                <button 
                  type="button" 
                  className="btn btn-note-stop" 
                  onClick={releaseNote}>
                    Stop Editing
                </button>

                {/* classes.updateButton */}
                {/* <div className={"btn btn-success"} onClick={updateNote}>
                  <h5>Save and submit</h5>
                </div> */}

                <button 
                  type="button" 
                  className="btn btn-success btn-note-save" 
                  onClick={updateNote}>
                    Save and submit
                </button>

              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default NoteInfo;
