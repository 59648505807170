import React, { useEffect, useState } from "react";
import { useStateValue } from "../../store/StateProvider";
import { createUseStyles } from "react-jss";
import { logout } from "../../store/actions/authActions";
import { selectUserNote, getUserNotes } from "../../store/actions/noteActions";
import NoteListItemSubmitted from "../noteListItemSubmitted";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Pagination from "../pagination/pagination";

const useStyles = createUseStyles({
  title: {
    alignSelf: "center",
    width: "100px",
  },
  backLink: {
    alignSelf: "center",
    width: "100%",
    color: "black",
    textAlign: "center",
    height: "40px",
    paddingTop: "5px",
  },
  noteList: {
    width: "300px" /* "25%" */,
    borderRight: "1px solid silver",
    // display: "flex",
    // flexDirection: "column",
    height: "70%",
    overflow: "auto",
  },
  noNotes: {
    alignSelf: "center",
    padding: "20px",
    fontColor: "grey",
  },
  noteMenu: {
    borderBottom: "1px solid silver",
    "& div:not(:first-child)": {
      borderLeft: "1px solid silver",
    },
    "& div:hover": {
      backgroundColor: "rgb(211, 217, 223)",
      padding: "10px 0px",
    },
    "& div": {
      padding: "10px 0px",
      cursor: "pointer",
    },
    "& div.selected": {
      backgroundColor: "rgb(211, 217, 223)",
    },
  },
});

function NoteListUser() {
  const classes = useStyles();
  const initialState = {
    activeNote: null,
  };
  const [state, dispatch] = useStateValue();
  const [listState, setActiveNote] = useState(initialState);
  const [currentPage, setCurrentPage] = useState(1);

  const isLastPage =
    state.notes.perPage * currentPage >= state.notes.totalCount;

  const fetchData = async (currentPage, userID) => {
    dispatch(await getUserNotes(currentPage, userID));
  };

  const setActive = (id) => {
    setActiveNote({
      ...listState,
      activeNote: id,
    });

    let list = state.notes.userNotes;
    dispatch(selectUserNote(list.find((item) => item.id === id)));
  };

  useEffect(() => {
    currentPage &&
      sessionStorage.getItem("selectedNoteUserId") &&
      fetchData(currentPage, sessionStorage.getItem("selectedNoteUserId"));
  }, [currentPage]);

  useEffect(() => {
    let id = setInterval(() => {
      currentPage &&
        sessionStorage.getItem("selectedNoteUserId") &&
        fetchData(currentPage, sessionStorage.getItem("selectedNoteUserId"));
    }, 2000);

    return () => {
      clearInterval(id);
    };
  }, [currentPage]);

  useEffect(() => {
    if (state.notes.error.errorCode === 401) {
      dispatch(logout());
    }
  }, [state.notes.error.errorCode]);

  return (() => {
    let list;
    list = (
      <div style={{ overflow: "auto" }}>
        {state.notes.userNotes.map((item, index) => (
          <NoteListItemSubmitted
            isActive={item.id === listState.activeNote}
            index={index + 1}
            key={item.id}
            note={item}
            setActive={setActive}
          />
        ))}
        {!state.notes.userNotes.length && (
          <div className={classes.noNotes}> No reviewed notes</div>
        )}
      </div>
    );
    return (
      <div>
        <Link to="/UserList">
          <div className={classes.backLink}>
            <ArrowBackIcon />
          </div>
        </Link>

        <div
          style={{
            marginLeft: 10,
            borderBottom: "1px solid silver",
            borderRight: "1px solid silver",
          }}
        >
          <p className={"text-center"}>
            All user notes: ({state.notes.totalCount})
          </p>
          <p>Name: {sessionStorage.getItem("userName")}</p>
          <p>Email: {sessionStorage.getItem("userEmail")}</p>
          <p>ID: {sessionStorage.getItem("selectedNoteUserId")}</p>
        </div>
        <div className={classes.noteList}>
          <div className="container"></div>
          {list}
          {state?.notes?.userNotes?.length > 0 && (
            <Pagination
              onPrevClick={() =>
                setCurrentPage((currentPage) => currentPage - 1)
              }
              onNextClick={() =>
                setCurrentPage((currentPage) => currentPage + 1)
              }
              isDisabledPrev={currentPage == 1}
              isDisabledNext={isLastPage}
            />
          )}
        </div>
      </div>
    );
  })();
}

export default NoteListUser;
