import { USER_ENDPOINT } from "../network/EndPoints";
import {
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
  deleteRequest,
  patchDownloadRequest,
} from "../network/ApiRequest";

export const loginApi = (payload) => {
  return postRequest(USER_ENDPOINT.LOGIN, payload);
};

// export const profileApi = (payload) => {
//   return putRequest(USER_ENDPOINT.PROFILE, payload);
// };

export const getAdminUsersApi = (payload) => {
  return getRequest(USER_ENDPOINT.ADMIN_USERS + payload);
};

export const adminEmailApi = (payload) => {
  return postRequest(USER_ENDPOINT.ADMIN_EMAIL, payload);
};

export const adminEmailAllApi = (payload) => {
  return postRequest(USER_ENDPOINT.ADMIN_EMAIL_ALL, payload);
};

export const adminNotificationSendApi = (payload) => {
  return postRequest(USER_ENDPOINT.ADMIN_NOTIFICATION_SEND, payload);
};

export const adminNotificationSendAllApi = (payload) => {
  return postRequest(USER_ENDPOINT.ADMIN_NOTIFICATION_SEND_ALL, payload);
};

export const adminPremiumSubscriptionApi = (payload) => {
  return postRequest(USER_ENDPOINT.ADMIN_PREMIUM_SUBSCRIPTION, payload);
};

export const getAdminNotesApi = (payload) => {
  return getRequest(USER_ENDPOINT.ADMIN_NOTES + payload);
};

export const getAdminNotesPutApi = (id, payload) => {
  return putRequest(USER_ENDPOINT.ADMIN_NOTES + id, payload);
};

export const getAdminNotesPostApi = (id) => {
  return postRequest(USER_ENDPOINT.ADMIN_NOTES + id);
};

export const processPendingNotesPatchApi = (payload) => {
  return patchRequest(USER_ENDPOINT.PROCESS_PENDING_NOTES, payload);
};