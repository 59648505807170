import React  from 'react';
import {createUseStyles} from 'react-jss'
import BlockIcon from '@material-ui/icons/Block';
import {useStateValue} from "../../store/StateProvider";
import { Checkbox } from '@material-ui/core';
import "./noteListItem.css";

const useStyles = createUseStyles({
    note:{
       // width: '50%',
        borderBottom: "1px solid silver",
        padding:"20px",

        '&:hover':{
            cursor:'pointer',
            backgroundColor: 'rgb(211, 217, 223)'
        }
    },
    active:{
        backgroundColor:"rgb(211, 217, 223)"
    },

    locked:{
        backgroundColor:"rgb(148, 148, 148)",

    }
})

function NoteListItem({
                       note, setActive, isActive, isLocked, index,
                       lockAcquirePendingNote,
                       isAnyNoteActive, lockReleasePendingNote, isAllowMultiSelection
                        }) {

    const [state] = useStateValue();
    const classes = useStyles()
    const handleClickListItem = () => {
        if(isAnyNoteActive && (isAnyNoteActive !== note.id)) {
            if(window.confirm("Are you sure you want to stop editing this note without saving and submitting it?")){
                if(isLocked){
                    alert("This note is being edited by another Operator. Proceed to the next one.")
                } else {
                    lockReleasePendingNote(isAnyNoteActive)
                    setActive(note.id);
                    state.notes.selectedNote.isThatNoteReminder = false;
                    lockAcquirePendingNote(note.id);
                    sessionStorage.setItem('selectedNoteUserId', note.user_id);
                }
            }
        }
        else {
            if(isLocked){
                alert("This note is being edited by another Operator. Proceed to the next one.")
            } else {
                setActive(note.id);
                state.notes.selectedNote.isThatNoteReminder = false;
                lockAcquirePendingNote(note.id);
                sessionStorage.setItem('selectedNoteUserId',note.user_id);
            }
        }
    }
    return (
        <>
            <div className='senstone-note'>
                {isAllowMultiSelection && (
                <div className='checkbox-parent'>
                    <input 
                        type="checkbox" 
                        name={note.id} 
                        id={note.id} 
                        value={note.id}
                    />
                </div>
                )}
                
                <div onClick = {handleClickListItem}
                    className = {classes.note + " " + (isActive? classes.active + "  note-div ":" note-div ") + (isLocked? classes.locked:"") + (isAllowMultiSelection?"":" note-without-checkbox")}>
                    {index}
                    { ") "}
                    { !isLocked && ((note.text === undefined) ? "" : note.text.substring(0, 20)) }
                    {isLocked && <BlockIcon color="secondary" />}
                </div>
            </div>
        </>
    );
}

export default NoteListItem