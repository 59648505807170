import React from "react";
import { useStateValue} from '../store/StateProvider'
import { Route, Redirect } from "react-router-dom";

function GuestRoute({ children, ...rest }) {
    const [state] = useStateValue();

    if (state.auth.isAuthenticated){
        return <Redirect  to="/"/>
    }

    return <Route {...rest}/>
    ;
}
export default GuestRoute