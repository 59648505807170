import React, {useEffect, useState} from 'react';
import {useStateValue} from '../../store/StateProvider'
import {createUseStyles} from 'react-jss'
import { logout } from "../../store/actions/authActions"
import getUserInformationForNote, {  getCheckedNotes, selectCheckedNote} from "../../store/actions/noteActions"

import NoteListItemSubmitted from "../noteListItemSubmitted"
import Pagination from '../pagination/pagination';

const REWIEVED = "reviewed"

const useStyles = createUseStyles({
    title:{
        alignSelf: "center",
        width: "100px"
    },
    noteList:{
        width: "250px"  /* "25%" */,
        borderRight: "1px solid silver",
       // display: "flex",
       // flexDirection: "column",
        height:"100%",
        overflow:"auto"
    },
    noNotes:{
        alignSelf: "center",
        padding: "20px",
        fontColor: "grey",
    },
    noteMenu:{
        borderBottom: "1px solid silver",
        "& div:not(:first-child)": {
            borderLeft: "1px solid silver"
        },
        "& div:hover":{
            backgroundColor: 'rgb(211, 217, 223)',
            padding: "10px 0px",
        },
        "& div":{
            padding: "10px 0px",
            cursor: "pointer"
        },
        "& div.selected":{
            backgroundColor: 'rgb(211, 217, 223)',
        }
    }
})

function  NoteListSubmitted() {

    const classes = useStyles()
    const initialState = {
        activeNote: null,
    };

    const [state, dispatch] = useStateValue();
    const [listState, setActiveNote] = useState(initialState);
    const [menuItem, setMenuItem] = useState("reviewed");
    const [currentPage, setCurrentPage] = useState(1);

    const isLastPage = (state.notes.perPage * currentPage) >= state.notes.totalCount;

    const fetchData = async (currentPage) => {
        currentPage && dispatch(await getCheckedNotes(currentPage))
    }

    const setActive = async (id) => {
        dispatch(await getUserInformationForNote());

        setActiveNote(
            {
                ...listState,
                activeNote: id
            })

        let list = state.notes.checkedNotes
        dispatch(selectCheckedNote(list.find(item => item.id === id)))
    }

    useEffect(() => {
        currentPage && fetchData(currentPage);
    }, [currentPage]);

    useEffect(() => {
        let id = setInterval(() => {
            fetchData(currentPage);
        }, 5000)
        return () => {
            clearInterval(id)
        }
    },[currentPage])

    useEffect (() => {
        if (state.notes.error.errorCode === 401){
            dispatch(logout())
        }
    }, [state.notes.error.errorCode])

    const switchMenu = menuName => {
        setMenuItem(menuName)
        setActiveNote(initialState)
        dispatch(selectCheckedNote({}))
    }

    return (() => {
        let list
            list = <div style = {{overflow:"auto"}}>
                {
                    state.notes.checkedNotes.map( (item, index) =>
                        <NoteListItemSubmitted isActive={item.id === listState.activeNote}
                                      index={index + 1}
                                      key={item.id}
                                      note={item}
                                      setActive={setActive}/>)
                }
                {!state.notes.checkedNotes.length && <div className={classes.noNotes}> No reviewed notes</div>}
            </div>
        return <div className={classes.noteList}>
            <div>
                <div className = "container">
                    <div className = {classes.noteMenu + " row"}>
                        <div onClick = {()=>switchMenu(REWIEVED)}
                             className = {"col-6 text-center" + (menuItem === REWIEVED ? " selected": " ")}
                        >
                            Submitted notes
                        </div>
                    </div>
                </div>
            </div>
            {list}
            {state?.notes?.checkedNotes?.length > 0 &&
            <Pagination
                onPrevClick={() => setCurrentPage((currentPage) => currentPage - 1)}
                onNextClick={() => setCurrentPage((currentPage) => currentPage + 1)}
                isDisabledPrev={currentPage == 1}
                isDisabledNext={isLastPage}
            />
            }
        </div>
    }) ();
}
export default NoteListSubmitted